import React from "react";
import { useField, useFormikContext } from "formik";
import { ToggleButtonGroup, ToggleButton, Icon } from "@mui/material";

// material ui styled
import { styled } from "@mui/material/styles";

// StyledToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: theme.palette.grey[200],
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[50],
  },
}));

export default function ToggleButtonGroupField(props) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const { name, label, options, setContextValue, ...rest } = props;

  // handleChange
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== undefined || value !== null) {
      setFieldValue(name, value);
      setContextValue(name, value);
    }
  };

  return (
    <ToggleButtonGroup
      {...field}
      {...rest}
      color="neutral"
      sx={{
        backgroundColor: "primary.main",
      }}
      exclusive
      onChange={handleChange}
      size="small"
    >
      {options.map((option) => (
        <StyledToggleButton key={option.code} name={name} value={option.code}>
          {option.name}
          <Icon fontSize="small" sx={{ ml: 1 }}>
            {option.icon}
          </Icon>
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
