import React from "react";

// components
import Hero from "../components/Home/Hero";
import Services from "../components/Home/Services";
import About from "../components/Home/About";
import Portfolio from "../components/Home/Portfolio";
import Reviews from "../components/Home/Reviews";
import Contact from "../components/Home/Contact";
import BackToTop from "../components/Home/BackToTop";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <About id="about" />
      <Services id="services" />
      <Portfolio id="portfolio" />
      <Reviews id="reviews" />
      <Contact id="contact" />
      <BackToTop />
    </React.Fragment>
  );
};

export default Home;
