import * as React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { grey } from "@mui/material/colors";
import { useAuth } from "../../hooks/useAuth";

// config
import { config } from "../../config";

import {
  Grid,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Stack,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// material ui icons
import MenuIcon from "@mui/icons-material/Menu";
import SecurityIcon from "@mui/icons-material/Security";

// components
import { Help } from "../Widgets";
import Signin from "../../pages/Auth/Signin";

// material ui styled
import { styled } from "@mui/material/styles";

// assets
import LogoImg from "../../assets/logo.png";

// Logo
const Logo = styled("img")(({ theme }) => ({
  //width: "250px",
}));

const pages = [
  "About",
  "Services",
  "Transportation",
  "Hotel",
  "Portfolio",
  "Reviews",
  "Contact",
];

const Header = ({ mode }) => {
  const { isLogged } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isNormal = location.pathname === "/" ? true : false;

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    if (page === "transportation") {
      window.open(
        "https://admin.travelserve.net/cloud/",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (page === "hotel") {
      window.open(
        "https://hotel.travelserve.net/",
        "_blank",
        "noopener,noreferrer"
      );
    }
    setAnchorElNav(null);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: `0px 0px 4px ${grey[200]}`,
      }}
      position="sticky"
      color="inherit"
    >
      <Box sx={{ backgroundColor: "primary.main" }}>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ py: 0.8 }}
          >
            <Grid item sm={6}>
              <Help
                color="common.white"
                sx={{ fontSize: "75%" }}
                telephone={true.toString()}
                whatsapp={true.toString()}
              />
            </Grid>
            <Grid
              item
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Help
                color="common.white"
                sx={{ fontSize: "75%" }}
                email={true.toString()}
                booking={true.toString()}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ my: 1.5 }}>
        <Toolbar disableGutters>
          {/* logo:md */}
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: "100%",
            }}
          >
            {isNormal ? (
              <Logo
                src={LogoImg}
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 5,
                  cursor: "pointer",
                }}
                alt={config.client.name}
                onClick={scrollToTop}
              />
            ) : (
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Logo
                  src={LogoImg}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 5,
                    cursor: "pointer",
                  }}
                  alt={config.client.name}
                  onClick={handleClick}
                />
                {!isLogged && (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <SecurityIcon sx={{ color: grey[600] }} />
                    <Typography sx={{ pt: 0.4 }}>SECURE CHECKOUT</Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Box>
          {/* logo:md */}

          {/* menu:xs */}
          {isNormal && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu("")}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page}>
                    <Link
                      activeClass="active"
                      to={page.toLowerCase()}
                      spy={true}
                      smooth={true}
                      offset={-180}
                      duration={500}
                      onClick={() => handleCloseNavMenu(page.toLowerCase())}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {/* menu:xs */}

          {/* logo:xs */}
          <Box
            sx={{
              width: "100%",
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Logo
              src={LogoImg}
              sx={{ width: "180px", cursor: "pointer" }}
              alt={config.client.name}
              onClick={isNormal ? scrollToTop : handleClick}
            />
          </Box>
          {/* logo:xs */}

          {/* menu:md */}
          {isNormal && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page}
                  activeClass="active"
                  to={page.toLowerCase()}
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  <Button
                    size="large"
                    onClick={() => handleCloseNavMenu(page.toLowerCase())}
                    sx={{ color: "primary", mr: 1.5 }}
                  >
                    {page}
                  </Button>
                </Link>
              ))}
            </Box>
          )}
          {/* menu:md */}

          <Box sx={{ flexGrow: 0 }}>
            <Signin />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
