import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useAuth } from "hooks/useAuth";
import { isObject } from "lodash";
import { flightNo, storage } from "utils";
import API from "api";

//icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";

// page
import Booking from "../Client/Booking";

export default function Bookings() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { client } = useAuth();
  const [open, setOpen] = React.useState(false);

  // isMgb
  const isMgb = isObject(storage.get("session", "mgb"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (params) => {
    storage.set("session", "mgb", params.row);
    handleClickOpen();
  };

  const columns = [
    { field: "bno", headerName: "Booking#" },
    { field: "meet_greet_type", headerName: "Service" },
    {
      field: "booking_date_time",
      type: "dateTime",
      headerName: "Flight Date & Time",
      width: 160,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm a");
      },
    },
    {
      field: "airline",
      headerName: "Flight#",
      width: 80,
      valueGetter: (params) => {
        return flightNo(params.row);
      },
    },
    { field: "terminal", headerName: "Package" },
    { field: "pax_names", headerName: "Passenger(s)", flex: 1 },
    { field: "status", headerName: "Status", width: 130 },
    {
      field: "datecreated",
      type: "dateTime",
      headerName: "Date Created",
      width: 130,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FileDownloadIcon sx={{ fontSize: "20px" }} />}
          label="PDF"
          onClick={() => handleClick(params)}
        />,
        <GridActionsCellItem
          icon={<LaunchIcon sx={{ fontSize: "20px" }} />}
          label="View Booking Details"
          onClick={() => {
            window.open(
              `/mg/${params.row.uuid}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        />,
      ],
    },
  ];

  // fetchBookings
  const fetchBookings = useCallback(async () => {
    await API.get(`/meetgreet/client/${client.uuid}`)
      .then((res) => setRows(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [client.uuid]);

  // useEffect
  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  return (
    <>
      {isMgb && <Booking handleClose={handleClose} open={open} />}
      <div style={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            flexGrow: 1,
            height: 635,
            width: "100%",
            "& .confirm": {
              backgroundColor: "#8bc34a",
            },
            "& .process": {
              backgroundColor: "#ff943975",
            },
          }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            getRowId={(row) => row.mgbid}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getCellClassName={(params) => {
              if (params.field === "status") {
                return params.formattedValue === "Confirmed"
                  ? "confirm"
                  : "process";
              }
            }}
          />
        </Box>
      </div>
    </>
  );
}
