import React from "react";

// components
import { MeetGreet } from "../components/MeetGreet";
import BackToTop from "../components/Home/BackToTop";

const Packages = () => {
  return (
    <React.Fragment>
      <MeetGreet />
      <BackToTop />
    </React.Fragment>
  );
};

export default Packages;
