import React, { useState, useEffect } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import API from "api";
import { useSnackbar } from "notistack";
import { errorMessage } from "utils";

export default function AutocompleteField(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, route, label, setContextValue, contextvalue, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { value: selectedValue } = field;
  const { setValue } = helper;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  //value
  const value = selectedValue ? selectedValue : contextvalue;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  function _onChange(e, v) {
    const val = v === null ? null : v.name;
    setValue(val);

    //set context value
    let packageDetails;
    if (field.name === "packageName") {
      let packageDetails = options.find((option) => option.name === val);
      if (packageDetails === undefined || packageDetails === null)
        packageDetails = {};
      setContextValue(field.name, val, packageDetails);
    } else if (setContextValue !== undefined) {
      setContextValue(field.name, val, packageDetails);
    }
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        if (route) {
          await API.get(`/${route}`)
            .then((res) => setOptions(res.data))
            .catch((e) => {
              enqueueSnackbar(errorMessage(e), { variant: "error" });
            });
        } else {
          setOptions(data);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, route, data, enqueueSnackbar]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...rest}
      //freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      defaultValue={value ? { name: value } : null}
      value={value ? { name: value } : null}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={(e, v) => _onChange(e, v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={meta.touched && meta.error && true}
          helperText={_renderHelperText()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
