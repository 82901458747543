import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "hooks/useGreeterAuth";

// config
import { config } from "config";

// material ui components
import {
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Link,
  Button,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// loading button
import { LoadingButton } from "@mui/lab";

// assets
import LogoImg from "assets/logo.png";

// initial values
const initialValuesSignIn = {
  username: "",
  password: "",
  remember: true,
};

// validation schema
const validationSchemaSignIn = Yup.object().shape({
  username: Yup.string()
    .min(4, "Username must be of 4 characters length")
    .required("Username is required"),
  password: Yup.string()
    .min(6, "Password must be of 6 characters length")
    .required("Password is required"),
});

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState([false, false]);

  const { authLogin } = useAuth();

  const handleSubmitSignIn = async (values) => {
    setLoading([true, false]);
    try {
      await authLogin(values.username, values.password, values.remember);
      setLoading([false, false]);
      navigate("/greeter/dashboard");
    } catch (e) {
      setLoading([false, false]);
      enqueueSnackbar(e.response.data.message, { variant: "error" });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Link component={RouterLink} to="/">
                <img src={LogoImg} alt={config.client.name} />
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ mt: 0 }}
        >
          <Grid item sm={6} xs={12}>
            <Formik
              initialValues={initialValuesSignIn}
              validationSchema={validationSchemaSignIn}
              onSubmit={handleSubmitSignIn}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 5,
                      borderRadius: "4px",
                      boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px",
                      height: "440px",
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{
                        pb: 2,
                        fontSize: { xs: "22px" },
                        fontWeight: "bold",
                      }}
                    >
                      Meeter Greeter Sign in
                    </Typography>
                    <Typography variant="caption" sx={{ pb: 2 }}>
                      If you're a meeter greeter, please login with provided
                      Username and Password.
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        fullWidth
                        type="text"
                        name="username"
                        label="Username"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.username}
                        onChange={handleChange}
                        helperText={touched.username && errors.username}
                        error={Boolean(errors.username && touched.username)}
                        sx={{ mb: 3 }}
                      />
                      <TextField
                        fullWidth
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        error={Boolean(errors.password && touched.password)}
                        sx={{ mb: 1.5 }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="remember"
                            onChange={handleChange}
                            checked={values.remember}
                            color="primary"
                          />
                        }
                        label="Remember me"
                      />
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading[0]}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign In
                      </LoadingButton>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Typography variant="caption" color="secondary.dark">
              If you can't sign in to your account, please call on{" "}
              <Link
                href={`tel:${config.client.telephone.replace(
                  /[^A-Z0-9]+/gi,
                  ""
                )}`}
                color="inherit"
                underline="none"
              >
                {config.client.telephone}
              </Link>{" "}
              or email at{" "}
              <Link
                href={`mailto:${config.client.email}`}
                color="inherit"
                underline="none"
              >
                {config.client.email}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ fontWeight: "bold" }}
              component={RouterLink}
              to="/"
              endIcon={<ArrowRightAltIcon fontSize="small" />}
            >
              Go to Home{" "}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
