import { useState } from "react";
import { Stack, IconButton, Box, ButtonGroup, Typography } from "@mui/material";
import { formatCurrency } from "utils";

import { styled } from "@mui/material/styles";

// material icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function CounterField(props) {
  const { options, values, updateValues } = props;

  const [pax, setPax] = useState(options);

  // handlePlus
  const handlePlus = (item, index) => {
    const newPax = [...pax];
    if (Number(newPax[index].value) < Number(newPax[index].max)) {
      newPax[index].value = item.value + 1;
      setPax(newPax);
      updateValues([newPax[index].id], newPax[index].value);
    }
  };

  // handleMinus
  const handleMinus = (item, index) => {
    const newPax = [...pax];
    if (newPax[index].value > 1) {
      newPax[index].value = item.value - 1;
    } else if ((newPax[index].value = 1)) {
      item.id === "adults"
        ? (newPax[index].value = 1)
        : (newPax[index].value = 0);
    }
    setPax(newPax);
    updateValues([newPax[index].id], newPax[index].value);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      {pax.map((item, index) => (
        <Item key={item.id}>
          <Typography variant="subtitle2">{item.name}</Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "0.7rem", my: 1, color: "secondary.main" }}
          >
            {item.description ||
              `${formatCurrency(
                values.packageDetails[item.id + "_price"]
              )} inc VAT`}
          </Typography>
          <ButtonGroup>
            <IconButton
              size="small"
              color={values[item.id] <= 0 ? "secondary" : "primary"}
              onClick={() => handleMinus(item, index)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton size="small" disabled>
              {values[item.id]}
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => handlePlus(item, index)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </ButtonGroup>
        </Item>
      ))}
    </Stack>
  );
}
