import React from "react";
import { Link as RouterLink } from "react-router-dom";

// material ui components
import { Box, Typography, Link, Stack, Tooltip, Divider } from "@mui/material";

// material ui icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

// config
import { config } from "../../config";

const Help = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.telephone && (
        <Tooltip title="Call Us" placement="bottom">
          <Typography {...props}>
            <Link
              href={`tel:${config.client.telephone.replace(
                /[^A-Z0-9]+/gi,
                ""
              )}`}
              color="inherit"
              underline="none"
            >{`Need Help Booking? Call ${config.client.telephone}`}</Link>
          </Typography>
        </Tooltip>
      )}

      {props.whatsapp && (
        <Tooltip title="WhatsApp Us" placement="bottom">
          <Link
            href={`https://wa.me/${config.client.whatsApp}`}
            rel="noopener"
            target="_blank"
            color="inherit"
            underline="none"
            sx={{ mx: 1 }}
          >
            <WhatsAppIcon fontSize="small" sx={{ color: props.color }} />
          </Link>
        </Tooltip>
      )}

      {props.email && (
        <Tooltip title="Email Us" placement="bottom">
          <Link
            href={`mailto:${config.client.emailConcierge}`}
            color="inherit"
            underline="none"
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <MailOutlineIcon
                fontSize="small"
                sx={{ color: props.color, mr: 0.8 }}
              />
              <Typography {...props}>{config.client.emailConcierge}</Typography>
            </Stack>
          </Link>
        </Tooltip>
      )}

      {props.booking && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 1, bgcolor: "secondary.light" }}
          />
          <Tooltip title="My Bookings" placement="bottom">
            <Link
              component={RouterLink}
              to="/dashboard"
              color="inherit"
              underline="none"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <PersonOutlineIcon
                  fontSize="small"
                  sx={{ color: props.color, mr: 0.8 }}
                />
                <Typography {...props}>My Bookings</Typography>
              </Stack>
            </Link>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default Help;
