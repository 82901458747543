import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useField } from "formik";

export default function TimePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value: selectedValue } = field;
  const [timeValue, setTimeValue] = useState(null);

  useEffect(() => {
    if (selectedValue) {
      const time = new Date(selectedValue);
      setTimeValue(time);
    }
  }, [selectedValue]);

  function _onChange(time) {
    if (time) {
      setValue(time);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileTimePicker
        {...props}
        value={timeValue}
        onChange={_onChange}
        renderInput={(params) => (
          <TextField
            helperText={isError && error}
            {...params}
            error={isError}
          />
        )}
      />
    </LocalizationProvider>
  );
}
