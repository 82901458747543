import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import API from "api";
import { useAuth } from "../../hooks/useGreeterAuth";

import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { storage } from "utils";

const ToggleButtons = styled(ToggleButton)({
  "&.MuiToggleButton-root": {
    textTransform: "capitalize",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    textTransform: "capitalize",
    color: "white",
    backgroundColor: "#f73378",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Notes = (props) => {
  const { greeter } = useAuth();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [status, setStatus] = React.useState("confirmed");
  const [notes, setNotes] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { handleClose, open } = props;

  let mgb = storage.get("session", "mgb");

  const handleChange = (event, newStatus) => {
    if (newStatus !== "confirmed" && !notes) {
      setError(true);
    } else {
      setError(false);
    }
    setStatus(newStatus);
  };

  const handleSubmit = async () => {
    if (!status && !notes && status !== "confirmed") {
      setError(true);
    } else {
      let greeterNotes = `Greeter "${
        greeter.first_name
      }" sent status "${status.toUpperCase()}" with notes "${
        notes ? notes : "empty"
      }"`;
      setLoading(true);
      await API.post(`/meetgreet/greeter/${mgb.uuid}`, {
        email: greeter.email,
        notes: greeterNotes,
      })
        .then((res) => {
          setNotes("");
          setSuccess(true);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      {mgb && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            sx={{ color: "#f50057" }}
          >
            Booking #: {mgb.bno}
          </BootstrapDialogTitle>
          <DialogContent>
            <Box
              sx={{
                width: 600,
                maxWidth: "100%",
              }}
            >
              <Typography sx={{ mb: 1 }}>
                Select status and enter notes(if not confirmed)
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={status}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
              >
                <ToggleButtons value="confirmed">Confrimed</ToggleButtons>
                <ToggleButtons value="cancelled">Cancelled</ToggleButtons>
                <ToggleButtons value="others">Others</ToggleButtons>
              </ToggleButtonGroup>
              <TextField
                value={notes}
                error={error}
                helperText={error ? "Notes Required" : ""}
                sx={{ mt: 2 }}
                id="outlined-multiline-static"
                label="Booking Notes"
                multiline
                rows={8}
                fullWidth
                onChange={(e) => {
                  if (!e.target.value && status !== "confirmed") {
                    setError(true);
                  } else {
                    setError(false);
                  }
                  setNotes(e.target.value);
                }}
              />
            </Box>
            {success && (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccess(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mt: 2 }}
              >
                Your message has been sent successfully.
              </Alert>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </BootstrapDialog>
      )}
    </div>
  );
};

export default Notes;
