import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";

// theme
import theme from "../../theme";

import ServiceImage1 from "../../assets/service-1.jpg";
import ServiceImage2 from "../../assets/service-2.jpg";
import ServiceImage3 from "../../assets/service-3.jpg";

// data
const services = [
  {
    id: 1,
    icon: "luggage",
    img: ServiceImage1,
    title: "Arrival Service",
    description:
      "The agent will monitor the status of your flight and meet you at the arrival gate/air bridge with a personalized signage. The agent will escort you through passport control and customs – Fast-tracked (where available). The agent will escort you on to the baggage reclaim hall, assist with luggage and arrange porters if requested. The agent will liaise with your onward transport provider and escort you to arrivals or outside, depending on your mode of transport.",
  },
  {
    id: 2,
    icon: "handshake",
    img: ServiceImage2,
    title: "Departure Service",
    description:
      "You will be welcomed by our agent at the pre-arranged Meet & Assist point. The agent will escort you to check-in and through passport control – Fast-tracked. You will be escorted to the lounge where you can relax and enjoy until the agent notifies you when it’s time to board – Lounge access (where available). The agent will escort and provide information about it shops, restaurants and services. The agent will swiftly escort you to the departure gate when it’s time to board.",
  },
  {
    id: 3,
    icon: "theater_comedy",
    img: ServiceImage3,
    title: "Connection Service",
    description:
      "The agent will monitor the status of your flight and meet you at the arrival gate/air bridge with a personalized signage. The agent will escort you through passport control and customs – Fast-tracked (where available). The agent will escort you to check in and baggage reclaim if required. Transport can be arranged if required between terminals. The agent will assist with all aspects of your connection service, from the moment you land and until you depart on the next flight.",
  },
];

const Services = ({ id }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 2,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Services
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 3 }}
          gutterBottom
        >
          Our Airport Meet & Greet Services
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 5 }} gutterBottom>
          Travel Serve VIP Services for Arrivals, Departures and Connections at
          London Heathrow Airport.
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {services.map((service) => (
          <Grid key={service.id} item xs={12} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  image={service.img}
                  alt={service.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
