import React, { createContext, useEffect, useReducer } from "react";
import { has } from "lodash";
import { storage } from "../utils";
import API from "../api";
import { Loading } from "../components/Loading";

// hooks
import useMeetGreet from "../hooks/useMeetGreet";

const initialState = {
  isLogged: false,
  isInit: false,
  client: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_INIT": {
      const { isLogged, client } = action.payload;

      return {
        ...state,
        isLogged: isLogged,
        isInit: true,
        client: client,
      };
    }

    case "AUTH_LOGIN": {
      const { client } = action.payload;

      return {
        ...state,
        isLogged: true,
        client: client,
      };
    }

    case "AUTH_LOGOUT": {
      return {
        ...state,
        isLogged: false,
        client: null,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const AuthContext = createContext({
  ...initialState,
  authLogin: () => {},
  authLogout: () => {},
  authInit: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { setmgValues } = useMeetGreet();

  const authCheckBooking = async (bno, email) => {
    const response = await API.get(`/meetgreet/${bno}/${email}`);

    const booking = response.data;

    //add to storage
    storage.set("session", "mgb", booking);
  };

  const authLogin = async (username, password, remember) => {
    const response = await API.post("/auth", {
      username: username,
      password: password,
      remember: remember,
    });

    const client = response.data;

    // set cid value to client.cid
    setmgValues("cid", client.cid);

    // storage
    const type = remember ? "local" : "session";

    //add to storage
    storage.set(type, "jwt", client);

    dispatch({
      type: "AUTH_LOGIN",
      payload: { client: client },
    });
  };

  const authLogout = () => {
    // remove from storage
    storage.remove("jwt");

    // set cid to zero
    setmgValues("cid", 0);

    dispatch({ type: "AUTH_LOGOUT" });
  };

  const authInit = async () => {
    const response = await API.get("/auth/me");
    const client = response.data;

    dispatch({
      type: "AUTH_INIT",
      payload: { isLogged: true, client: client },
    });
  };

  useEffect(() => {
    (async () => {
      const jwt = storage.get("local", "jwt") || storage.get("session", "jwt");

      if (has(jwt, "uuid")) {
        dispatch({
          type: "AUTH_INIT",
          payload: { isLogged: true, client: jwt },
        });
      } else {
        dispatch({
          type: "AUTH_INIT",
          payload: { isLogged: false, client: null },
        });
      }
    })();
  }, []);

  if (!state.isInit) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{ ...state, authLogin, authLogout, authInit, authCheckBooking }}
    >
      {children}
    </AuthContext.Provider>
  );
};
