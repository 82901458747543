import * as Yup from "yup";
import { mgFormModel } from "./formModel";
const {
  formField: {
    passengers,
    title,
    firstName,
    lastName,
    airline,
    flightNumber,
    flightClass,
    flightTime,
    noBags,
    mobile,
    email,
    terms,
  },
} = mgFormModel;

// regex
const flightRegEx =
  /^((?:[a-z][a-z]|[a-z][0-9]|[0-9][a-z])[a-z]?)([0-9]{1,4}[a-z]?)$/gi;

const validationSchema = [
  //PassengerForm
  Yup.object().shape({
    [passengers.name]: Yup.array().of(
      Yup.object().shape({
        [title.name]: Yup.string().required(`${title.requiredErrorMsg}`),
        [firstName.name]: Yup.string().required(
          `${firstName.requiredErrorMsg}`
        ),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
      })
    ),
  }),

  //FlightForm
  Yup.object().shape({
    [airline.name]: Yup.string().required(`${airline.requiredErrorMsg}`),
    [flightNumber.name]: Yup.string()
      .required(`${flightNumber.requiredErrorMsg}`)
      .matches(flightRegEx, flightNumber.invalidErrorMsg),
    [flightClass.name]: Yup.string().required(
      `${flightClass.requiredErrorMsg}`
    ),
    [flightTime.name]: Yup.string().required(`${flightTime.requiredErrorMsg}`),
    [noBags.name]: Yup.number()
      .required(`${noBags.requiredErrorMsg}`)
      .positive()
      .integer()
      .max(20),
  }),

  //PaymentForm
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [mobile.name]: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        `${mobile.invalidErrorMsg}`
      )
      .required(`${mobile.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email(`${email.invalidErrorMsg}`)
      .max(255)
      .required(`${email.requiredErrorMsg}`),
    [terms.name]: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
  }),

  //ClientForm
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  }),
];

export default validationSchema;
