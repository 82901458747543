import * as React from "react";
import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { config } from "../../config";

const Format = (props) => {
  return (
    <NumberFormat
      {...props}
      fixedDecimalScale={true}
      decimalScale={2}
      prefix={getSymbolFromCurrency(config.settings.currency)}
    />
  );
};

export default Format;
