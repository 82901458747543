import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Alert,
  Grid,
  Container,
  Link,
  Stack,
} from "@mui/material";
import { green } from "@mui/material/colors";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { errorMessage } from "utils";
import { Loading } from "components/Loading";
import API from "api";
import { BookingSummary } from "../Summary";
import { config } from "config";

const BookingConfirmation = () => {
  const navigation = useNavigate();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // fetchBookings
  const fetchBooking = useCallback(async () => {
    await API.get(`/meetgreet/v/${uuid}`)
      .then((res) => setData(res.data[0]))
      .catch((err) => setError(errorMessage(err)))
      .finally(() => setLoading(false));
  }, [uuid]);

  // useEffect
  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexGrow: 1,
          py: 4,
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <Loading />
        ) : (
          data && (
            <Container maxWidth="md">
              <Grid container direction="row" sx={{ pl: 4 }} spacing={4}>
                <Grid item md={8} xs={12}>
                  <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 4 }}>
                    <CheckCircleIcon sx={{ color: green[500] }} />
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ my: 4, color: green[500] }}
                    >
                      Booking Confirmed
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
                    Thank you for making payment and completing your booking
                    with us!
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
                    We are pleased to confirm that your booking number{" "}
                    <Box
                      component="span"
                      fontWeight="fontWeightBold"
                      sx={{ color: "#f50057" }}
                    >
                      {data.bno}
                    </Box>{" "}
                    is now confirmed and we have emailed your booking
                    confirmation.
                  </Typography>
                  <Typography>
                    Thank you for choosing us and to contact us regarding this
                    booking, please call on{" "}
                    <Link
                      href={`tel:${config.client.telephone.replace(
                        /[^A-Z0-9]+/gi,
                        ""
                      )}`}
                      color="inherit"
                      underline="none"
                    >
                      {config.client.telephone}
                    </Link>{" "}
                    or email at{" "}
                    <Link
                      href={`mailto:${config.client.emailConcierge}`}
                      color="inherit"
                      underline="none"
                    >
                      {config.client.emailConcierge}
                    </Link>
                  </Typography>
                  <Button
                    variant="text"
                    endIcon={<ArrowRightAltIcon />}
                    sx={{ mt: 2, mb: 8 }}
                    onClick={() => {
                      if (Number(data.cid) > 0) {
                        navigation("/dashboard");
                      } else {
                        navigation("/");
                      }
                    }}
                  >
                    {Number(data.cid) > 0
                      ? "Go to your Account"
                      : "Go to Homepage"}
                  </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                  <BookingSummary mgb={data} />
                </Grid>
              </Grid>
            </Container>
          )
        )}
      </Box>
    </React.Fragment>
  );
};

export default BookingConfirmation;
