import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { AllPages } from "./routes";
import { useRoutes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { GreeterAuthProvider } from "./contexts/GreeterAuthContext";
import { MeetGreetProvider } from "./contexts/MeetGreetContext";
import { CssBaseline } from "@mui/material";

// custom theme
import theme from "./theme";

const App = () => {
  const all_pages = useRoutes(AllPages());
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MeetGreetProvider>
        <AuthProvider>
          <GreeterAuthProvider>{all_pages}</GreeterAuthProvider>
        </AuthProvider>
      </MeetGreetProvider>
    </ThemeProvider>
  );
};

export default App;
