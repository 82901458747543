import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useField } from "formik";
import { formatISO } from "date-fns";

export default function DatePickerField(props) {
  const { name, size, setContextValue, fullWidth, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;

  // handleChange
  const handleChange = (date) => {
    if (date) {
      setValue(formatISO(date));
    } else {
      setValue(new Date());
    }
    if (setContextValue !== undefined) {
      setContextValue(name, formatISO(date));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        {...field}
        {...rest}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            fullWidth={fullWidth}
            size={size}
            helperText={isError && error}
            {...params}
            error={isError}
          />
        )}
      />
    </LocalizationProvider>
  );
}
