import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export default function CheckboxField(props) {
  const { label, defaultcontextvalue, contextvalue, setContextValue, ...rest } =
    props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText error={Boolean(error)}>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);

    // setcontextvalue
    if (contextvalue) {
      if (e.target.checked) {
        setContextValue(field.name, contextvalue);
      } else {
        setContextValue(field.name, defaultcontextvalue);
      }
    }
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.checked}
        checked={field.checked}
        control={
          <Checkbox
            sx={{ pb: 0.5 }}
            checked={field.value}
            {...field}
            onChange={_onChange}
          />
        }
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
