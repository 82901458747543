import { useAuth } from "../hooks/useGreeterAuth";
import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { isLogged } = useAuth();

  const [previouseRoute, setPreviousRoute] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (previouseRoute !== null) setPreviousRoute(pathname);
  }, [pathname, previouseRoute]);

  if (isLogged) return <>{children}</>;
  else {
    return (
      <Navigate to="/greeter/signin" state={{ redirectUrl: previouseRoute }} />
    );
  }
};

export default AuthGuard;
