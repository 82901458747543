import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import {
  Container,
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";

import API from "api";

const Delete = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 3 }}
          gutterBottom
        >
          Delete my TravelServe account
        </Typography>

        <Typography align="center" variant="body1" sx={{ mb: 4 }} gutterBottom>
          You can delete your TravelServe account from the TSL app or by using
          the following form.
        </Typography>

        <Box
          sx={{
            width: 500,
          }}
        >
          <Divider sx={{ mb: 2 }}>Submit a Request</Divider>
          <Typography
            align="center"
            variant="body2"
            sx={{ mb: 2 }}
            gutterBottom
          >
            We're sorry to see you go and if you're considering deleting your
            account, it's important to understand that this action is permanent.
          </Typography>

          <Typography
            align="center"
            variant="body2"
            sx={{ mb: 3 }}
            gutterBottom
          >
            If you want to delete your account, please fill out the following
            request form. Your request will be processed within 24 hours and our
            Customer Services team will confirm via email when completed.
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12} md={6}>
          <Paper
            variant="outlined"
            sx={{
              px: 1,
              py: 1,
            }}
          >
            {/* formik:start */}
            <Formik
              initialValues={{
                name: "",
                email: "",
                telephone: "",
                message: "",
              }}
              validate={(values) => {
                const errors = {};

                // name
                if (!values.name) {
                  errors.name = "Required";
                }

                // email
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                // telephone
                if (!values.telephone) {
                  errors.telephone = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  values.message = `[DELETE MY ACCOUNT] ${values.message}`;
                  await API.post("/email/contact", values);
                  setSubmitting(false);
                  enqueueSnackbar(
                    "You have successfully submitted your account deletion request.",
                    {
                      variant: "success",
                    }
                  );
                  resetForm();
                } catch (e) {
                  setSubmitting(false);
                  enqueueSnackbar(e.message, {
                    variant: "error",
                  });
                }
              }}
            >
              {({
                values,
                submitForm,
                resetForm,
                isSubmitting,
                touched,
                errors,
              }) => (
                <Form>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="name"
                      type="text"
                      label="Full name"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="email"
                      type="email"
                      label="Email"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="telephone"
                      type="text"
                      label="Telephone"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 2 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows="9"
                      name="message"
                      type="text"
                      label="Reason for deleting your account? (optional)"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 0 }}>
                    <Button
                      sx={{ margin: 1 }}
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{ margin: 1 }}
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            {/* formik:end */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Delete;
