import React from "react";
import { Container, Box, Typography, Grid, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAuth } from "../../hooks/useGreeterAuth";

import Bookings from "./Bookings";

const Greeter = () => {
  const { greeter, authLogout } = useAuth();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={8}>
            <Typography
              variant="h6"
              sx={{ pb: 4, fontSize: { xs: "16px" }, fontWeight: "bold" }}
            >
              Welcome back, {greeter.first_name}!{" "}
              <Button
                color="error"
                endIcon={<LogoutIcon fontSize="small" />}
                onClick={() => authLogout()}
              >
                Logout
              </Button>
            </Typography>
          </Grid>
        </Grid>

        <Bookings />
      </Container>
    </Box>
  );
};

export default Greeter;
