import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { storage } from "utils";

export default function OrderConfirmation() {
  const navigation = useNavigate();
  const mgb = storage.get("session", "mgb");

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Confiramtion
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mb: 1 }}>
        Thank you for completing your booking request with us!
      </Typography>
      <Typography sx={{ mt: 2 }} variant="subtitle1" gutterBottom>
        Your booking number is{" "}
        <Box
          component="span"
          fontWeight="fontWeightBold"
          sx={{ color: "#f50057" }}
        >
          {mgb.bno}
        </Box>{" "}
        and we have emailed your booking request summary. We will send you
        another email within next 24 hours after checking our availability.
      </Typography>
      {mgb.cid === 0 && (
        <Typography sx={{ mt: 2 }}>
          <strong style={{ color: "#eb4034" }}>PLEASE NOTE:</strong> This is not
          a final confirmation of your booking. We will check our availability
          and send you another email with online payment instructions.
        </Typography>
      )}
      <Button
        variant="text"
        endIcon={<ArrowRightAltIcon />}
        sx={{ mt: 2 }}
        onClick={() => {
          if (Number(mgb.cid) > 0) {
            navigation("/dashboard");
          } else {
            navigation("/");
          }
        }}
      >
        {Number(mgb.cid) > 0 ? "Go to your Account" : "Go to Homepage"}
      </Button>
    </React.Fragment>
  );
}
