import React from "react";
import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  InputField,
  AutocompleteField,
  SelectField,
  TimePickerField,
  CounterField,
} from "../../FormFields";
import { toCapitalize } from "utils";

// import data
import classes from "data/classes.json";
import extras from "data/extras.json";

export default function FlightForm(props) {
  const {
    mgValues,
    setmgValues,
    formField: {
      airline,
      flightNumber,
      flightClass,
      flightTime,
      noBags,
      connectingAirline,
      connectingFlightNumber,
    },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 3 }}>
        Flight Details
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {`${toCapitalize(mgValues.service)} Flight`}
            {" on "}
            {format(new Date(mgValues.flightDate), "EE, dd MMM yyyy")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AutocompleteField
            route="airlines"
            name={airline.name}
            label={airline.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            inputProps={{ style: { textTransform: "uppercase" } }}
            name={flightNumber.name}
            label={flightNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={flightClass.name}
            label={flightClass.label}
            data={classes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TimePickerField
            name={flightTime.name}
            label={flightTime.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={noBags.name} label={noBags.label} fullWidth />
        </Grid>
      </Grid>
      {mgValues.service === "connecting" && (
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Connecting Flight</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <AutocompleteField
              route="airlines"
              name={connectingAirline.name}
              label={connectingAirline.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputField
              inputProps={{ style: { textTransform: "uppercase" } }}
              name={connectingFlightNumber.name}
              label={connectingFlightNumber.label}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Flight Extras</Typography>
        </Grid>
        <Grid item xs={12}>
          <CounterField
            options={extras}
            values={mgValues}
            updateValues={setmgValues}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <CheckboxField
            name={porter.name}
            label={`${porter.label} ${formatCurrency(
              mgValues.packageDetails.porter_price
            )}`}
            context="mg"
            defaultcontextvalue={0}
            contextvalue={mgValues.packageDetails.porter_price}
            setContextValue={setmgValues}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={buggy.name}
            label={`${buggy.label} ${formatCurrency(
              mgValues.packageDetails.buggy_price
            )}`}
            context="mg"
            defaultcontextvalue={0}
            contextvalue={mgValues.packageDetails.buggy_price}
            setContextValue={setmgValues}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={wchr.name}
            label={`${wchr.label} ${formatCurrency(
              mgValues.packageDetails.wchr_price
            )}`}
            context="mg"
            defaultcontextvalue={0}
            contextvalue={mgValues.packageDetails.wchr_price}
            setContextValue={setmgValues}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={trolley.name}
            label={`${trolley.label} ${formatCurrency(
              mgValues.packageDetails.trolley_price
            )}`}
            context="mg"
            defaultcontextvalue={0}
            contextvalue={mgValues.packageDetails.trolley_price}
            setContextValue={setmgValues}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={lounge.name}
            label={`${lounge.label} ${formatCurrency(
              mgValues.packageDetails.lounge_price
            )}`}
            context="mg"
            defaultcontextvalue={0}
            contextvalue={mgValues.packageDetails.lounge_price}
            setContextValue={setmgValues}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
