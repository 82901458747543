import React, { createContext, useEffect, useReducer } from "react";
import { has } from "lodash";
import { storage } from "../utils";
import API from "../api";
import { Loading } from "../components/Loading";

const initialState = {
  isLogged: false,
  isInit: false,
  greeter: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_INIT": {
      const { isLogged, greeter } = action.payload;

      return {
        ...state,
        isLogged: isLogged,
        isInit: true,
        greeter: greeter,
      };
    }

    case "AUTH_LOGIN": {
      const { greeter } = action.payload;

      return {
        ...state,
        isLogged: true,
        greeter: greeter,
      };
    }

    case "AUTH_LOGOUT": {
      return {
        ...state,
        isLogged: false,
        greeter: null,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const GreeterAuthContext = createContext({
  ...initialState,
  authLogin: () => {},
  authLogout: () => {},
  authInit: () => {},
});

export const GreeterAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const authLogin = async (username, password, remember) => {
    const response = await API.post("/greeter/auth", {
      username: username,
      password: password,
      remember: remember,
    });

    const greeter = response.data;

    // storage
    const type = remember ? "local" : "session";

    //add to storage
    storage.set(type, "jwt_greeter", greeter);

    dispatch({
      type: "AUTH_LOGIN",
      payload: { greeter: greeter },
    });
  };

  const authLogout = () => {
    // remove from storage
    storage.remove("jwt_greeter");

    dispatch({ type: "AUTH_LOGOUT" });
  };

  const authInit = async () => {
    const response = await API.get("greeter/auth/me");
    const greeter = response.data;

    dispatch({
      type: "AUTH_INIT",
      payload: { isLogged: true, greeter: greeter },
    });
  };

  useEffect(() => {
    (async () => {
      const jwt_greeter =
        storage.get("local", "jwt_greeter") ||
        storage.get("session", "jwt_greeter");

      if (has(jwt_greeter, "uuid")) {
        dispatch({
          type: "AUTH_INIT",
          payload: { isLogged: true, greeter: jwt_greeter },
        });
      } else {
        dispatch({
          type: "AUTH_INIT",
          payload: { isLogged: false, greeter: null },
        });
      }
    })();
  }, []);

  if (!state.isInit) {
    return <Loading />;
  }

  return (
    <GreeterAuthContext.Provider
      value={{ ...state, authLogin, authLogout, authInit }}
    >
      {children}
    </GreeterAuthContext.Provider>
  );
};
