import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

// material ui components
import {
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Menu,
  MenuItem,
} from "@mui/material";

// material ui icons
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

// loading button
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// initial login credentials
const initialValues = {
  username: "",
  password: "",
  remember: true,
};

// validation schema
const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(4, "Username must be of 4 characters length")
    .required("Username is required"),
  password: Yup.string()
    .min(6, "Password must be of 6 characters length")
    .required("Password is required"),
});

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { isLogged, authLogin, authLogout } = useAuth();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await authLogin(values.username, values.password, values.remember);
      setLoading(false);
      setOpen(false);
      if (location.pathname === "/meetgreet") {
        navigate("/meetgreet", { replace: true });
      } else {
        navigate("/dashboard");
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.response.data.message, { variant: "error" });
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAnchor = Boolean(anchorEl);

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{
          display: { xs: "none", md: "flex" },
        }}
        variant="contained"
        startIcon={isLogged ? <LogoutIcon /> : <LoginIcon />}
        onClick={isLogged ? authLogout : handleClickAnchor}
      >
        {isLogged ? "Logout" : "Login"}
      </Button>

      <IconButton
        onClick={isLogged ? authLogout : handleClickAnchor}
        sx={{
          display: { xs: "flex", md: "none" },
        }}
      >
        <LogoutIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openAnchor}
        onClose={handleCloseAnchor}
      >
        <Box sx={{ width: 140 }}>
          <MenuItem
            onClick={() => {
              handleCloseAnchor();
              handleClickOpen();
            }}
          >
            Client Login
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/greeter/signin"
            onClick={handleCloseAnchor}
          >
            Greeter Login
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/login"
            onClick={handleCloseAnchor}
          >
            Find My Booking
          </MenuItem>
        </Box>
      </Menu>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { position: "absolute", top: 20 } }}
        TransitionComponent={Transition}
        keepMounted
      >
        <Container component="main" maxWidth="xs">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 5,
                  }}
                >
                  <Typography component="h1" variant="h5" sx={{ pb: 3 }}>
                    Client Log in
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      type="text"
                      name="username"
                      label="Username"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.username}
                      onChange={handleChange}
                      helperText={touched.username && errors.username}
                      error={Boolean(errors.username && touched.username)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 1.5 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="remember"
                          onChange={handleChange}
                          checked={values.remember}
                          color="primary"
                        />
                      }
                      label="Remember me"
                    />
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={loading}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign In
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Dialog>
    </div>
  );
};

export default Login;
