import React, { useState, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/Download";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import jsPDF from "jspdf";
import { config } from "config";
import { storage, generateQRCode, flightNo } from "utils";
import autoTable from "jspdf-autotable";
import { format } from "date-fns";

// company logo
import logo from "../../assets/logo.png";

const print = (mgb) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  });

  // logo
  doc.addImage(logo, "PNG", 20, 20, 187, 35);

  // company address, phone, email
  doc.setFontSize(9);
  doc.text(
    `${config.client.name}, ${config.client.address1} ${config.client.address2}, ${config.client.city}, ${config.client.postalCode}, ${config.client.country}`,
    20,
    75
  );
  doc.text(
    `t: ${config.client.telephone} e: ${config.client.email} w: ${config.client.domain}`,
    20,
    90
  );

  // line
  doc.setLineWidth(0.5);
  doc.line(20, 100, 570, 100);

  // qr code
  doc.addImage(generateQRCode(mgb.uuid), "JPEG", 240, 110, 100, 100);

  autoTable(doc, {
    startY: 225,
    styles: { cellPadding: 12 },
    head: [{ id: "ID", name: "Name" }],
    showHead: false,
    columnStyles: {
      id: { textColor: 0, fontStyle: "bold", cellWidth: 150 },
    },
    body: [
      ["Booking #:", mgb.bno],
      [
        "Service:",
        `${mgb.meet_greet_type} ${mgb.airline ? ": " + mgb.airline : ""}`,
      ],
      ["Terminal:", mgb.terminal],
      ["Flight Date:", format(new Date(mgb.booking_date_time), "dd MMM yyyy")],
      ["Flight Time:", format(new Date(mgb.booking_date_time), "hh:mm a")],
      ["Flight No:", flightNo(mgb)],
      [
        "Total Passengers(s):",
        `Adults(${mgb.adults}), Children(${mgb.children}), Infants(${mgb.infants}) = ${mgb.no_pax}`,
      ],
      ["Passenger(s) Name(s):", mgb.pax_names],
      ["Contact No:", mgb.contact_no],
      [
        "Extra(s):",
        `Buggy(${mgb.buggy}), Porter(${mgb.porter}), Additional Greeter(${mgb.trolley})`,
      ],
      ["Greeter(s):", mgb.greeters],
    ],
  });

  var width = doc.internal.pageSize.getWidth();
  doc.text(
    `Thank you for booking with ${config.client.name}`,
    width / 2,
    doc.lastAutoTable.finalY + 30,
    { align: "center" }
  );

  doc.save(mgb.bno);
};

const Pdf = () => {
  const [data, setData] = useState();

  useEffect(() => {
    getMGB();
  }, []);

  function getMGB() {
    setData(storage.get("session", "mgb"));
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        color="primary"
        endIcon={<DownloadIcon />}
        onClick={() => print(data)}
      >
        Download Voucher
      </Button>
      <Button
        variant="contained"
        sx={{ backgroundColor: blue[700] }}
        endIcon={<LocalPrintshopIcon />}
        onClick={() => {
          window.open(`/mg/${data.uuid}`, "_blank", "noopener,noreferrer");
        }}
      >
        Print Booking
      </Button>
    </Stack>
  );
};

export default Pdf;
