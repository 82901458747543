import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

// components
import MeetGreetWidgetForm from "components/MeetGreet/Forms/WidgetForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MeetGreetWidget = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color={props.color}
        size={props.size}
        variant={props.variant}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {props.label}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{ sx: { position: "absolute", top: 20 } }}
      >
        <DialogContent>
          <MeetGreetWidgetForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MeetGreetWidget;
