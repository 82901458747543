import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Paper,
  IconButton,
  Icon,
  Link,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";

// theme
import theme from "../../theme";

// config
import { config } from "../../config";

// Map
import Map from "./Map";
import API from "api";

// materil icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PhonelinkIcon from "@mui/icons-material/Phonelink";

const About = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Our Location
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          Get In Touch
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          If you have a specific enquiry, please write to us at the addresses
          below or use the form below.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid item xs={12} md={4}>
          <Stack spacing={4}>
            <Paper
              variant="outlined"
              sx={{
                px: 3,
                py: 2,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <Grid container direction="row">
                <Grid item xs={3}>
                  <IconButton
                    disableRipple
                    color="primary"
                    size="large"
                    sx={{ border: "2px solid", cursor: "auto" }}
                  >
                    <Icon>
                      <PhonelinkIcon />
                    </Icon>
                  </IconButton>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Contact
                  </Typography>
                  <Box color="text.secondary" sx={{ my: 1 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Link
                          href={`mailto:${config.client.telephone}`}
                          underline="none"
                          color="inherit"
                        >
                          {config.client.telephone}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box color="text.secondary" sx={{ my: 1 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Link
                          href={`mailto:${config.client.email}`}
                          underline="none"
                        >
                          {config.client.email}
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          href={`mailto:${config.client.emailConcierge}`}
                          underline="none"
                        >
                          {config.client.emailConcierge}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    ({config.client.support})
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                px: 3,
                py: 2,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <Grid container direction="row">
                <Grid item xs={3}>
                  <IconButton
                    disableRipple
                    color="primary"
                    size="large"
                    sx={{ border: "2px solid", cursor: "auto" }}
                  >
                    <Icon>
                      <LocationOnIcon />
                    </Icon>
                  </IconButton>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Address
                  </Typography>
                  <Typography color="text.secondary">
                    {config.client.address1}
                  </Typography>
                  <Typography color="text.secondary">
                    {config.client.address2}
                  </Typography>
                  <Typography color="text.secondary">
                    {config.client.city}, {config.client.postalCode}
                  </Typography>
                  <Typography color="text.secondary">
                    {config.client.country}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                px: 3,
                py: 2,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <Grid container direction="row">
                <Grid item xs={3}>
                  <IconButton
                    disableRipple
                    color="primary"
                    size="large"
                    sx={{ border: "2px solid", cursor: "auto" }}
                  >
                    <Icon>
                      <WatchLaterIcon />
                    </Icon>
                  </IconButton>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Opening Times
                  </Typography>
                  <Typography color="text.secondary">
                    {config.client.openingHours}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper
            variant="outlined"
            sx={{
              px: 2,
              py: 2,
              "&:hover": {
                boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                transition: "all .3s ease-out 0s",
              },
            }}
          >
            {/* formik:start */}
            <Formik
              initialValues={{
                name: "",
                email: "",
                telephone: "",
                message: "",
              }}
              validate={(values) => {
                const errors = {};

                // name
                if (!values.name) {
                  errors.name = "Required";
                }

                // email
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                // telephone
                if (!values.telephone) {
                  errors.telephone = "Required";
                }

                // message
                if (!values.message) {
                  errors.message = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  await API.post("/email/contact", values);
                  setSubmitting(false);
                  enqueueSnackbar("Message was sent successfully", {
                    variant: "success",
                  });
                  resetForm();
                } catch (e) {
                  setSubmitting(false);
                  enqueueSnackbar(e.message, {
                    variant: "error",
                  });
                }
              }}
            >
              {({
                values,
                submitForm,
                resetForm,
                isSubmitting,
                touched,
                errors,
              }) => (
                <Form>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="name"
                      type="text"
                      label="Name"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="email"
                      type="email"
                      label="Email"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="telephone"
                      type="text"
                      label="Telephone"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows="9"
                      name="message"
                      type="text"
                      label="Your Message"
                    />
                  </Box>
                  <Box margin={1} sx={{ mb: 3 }}>
                    <Button
                      sx={{ margin: 1 }}
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{ margin: 1 }}
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            {/* formik:end */}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{
              height: "500px",
              "&:hover": {
                boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                transition: "all .3s ease-out 0s",
              },
            }}
          >
            <Map />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
