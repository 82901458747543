import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Rating,
  Avatar,
  Stack,
} from "@mui/material";

// theme
import theme from "../../theme";

// data
const reviews = [
  {
    id: 1,
    name: "Julie Thorne",
    company: "Mega Mox",
    bgColor: "#32a852",
    comment:
      "Excellent service, prompt action, all involved at Travel Serve, said what they were going to do & did what they said they were going to do. You can’t say that about too many organisations nowadays.",
  },
  {
    id: 2,
    name: "Mark Robinson",
    company: "Mega Mox",
    bgColor: "#323ca8",
    comment:
      "First class service,they take all the hassle out of your connecting flight with friendly helpful staff in particular Georgiana who always goes that extra mile, faultless company can highly recommend.",
  },
  {
    id: 3,
    name: "Linda Cowan",
    company: "Mega Mox",
    bgColor: "#a83271",
    comment:
      "Excellent service from Majid to facilitate my daughter's departure at Heathrow. She is a quite a nervous flyer. Being helped by Travel Serve and making sure that she had everything she needed. Many thanks!",
  },
];

const Reviews = ({ id }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 2,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Reviews
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 3 }}
          gutterBottom
        >
          Customer Reviews
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 5 }} gutterBottom>
          Our success is measured by how our clients feel about their meet &
          greet experience with us. These reviews affirm that we're onto
          something.
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {reviews.map((review) => (
          <Grid key={review.id} item xs={12} md={4}>
            <Card
              sx={{
                maxWidth: 345,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Rating defaultValue={5} size="large" sx={{ pb: 4 }} />
                  <Typography color="text.secondary">
                    {review.comment}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Avatar
                      sx={{ bgcolor: review.bgColor }}
                      alt={review.name}
                      src="/static/images/avatar/1.jpg"
                    />
                    <Typography gutterBottom variant="h6" component="div">
                      {review.name}
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Reviews;
