import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";

import Bookings from "./Bookings";
import Widget from "./Widget";

const Client = () => {
  const { client } = useAuth();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container sx={{ pb: { xs: 2, sm: 3 } }}>
          <Grid item sm={8}>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "16px" }, fontWeight: "bold" }}
            >
              Welcome back, {client.company_name}!
            </Typography>
          </Grid>
          <Grid item sm={4} sx={{ textAlign: "right" }}>
            <Widget variant="contained" label="Create New Booking" />
          </Grid>
        </Grid>

        <Bookings />
      </Container>
    </Box>
  );
};

export default Client;
