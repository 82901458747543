import React from "react";
import { Box, Typography } from "@mui/material";

const Redirect = (props) => {
  // redirect
  window.location = props.location;

  return (
    <Box sx={{ my: 2, ml: 2 }}>
      <Typography>
        You are being redirected to Travel Serve new location...
      </Typography>
      <Typography sx={{ mt: 4 }}>
        Please do not close or leave this page.
      </Typography>
    </Box>
  );
};

export default Redirect;
