// config
export const config = {
  dev: {
    name: "Maniva",
    company: "Geektive Limited",
    website: "https://www.geektive.com/",
    version: "0.1.0",
  },
  api: {
    //baseURL: "http://127.0.0.1:8080",
    //baseURL: "http://172.16.10.186:8080",
    baseURL: "https://ts-api.onrender.com",
  },
  client: {
    name: "Travel Serve Limited",
    companyNumber: "08814015",
    telephone: "+44(0)207 148 1900",
    whatsApp: "4407496890777",
    email: "bookings@travelserve.net",
    emailConcierge: "concierge@travelserve.net",
    address1: "Novotel London Heathrow",
    address2: "Cherry Lane, Heathrow",
    city: "London",
    postalCode: "UB7 9HJ",
    country: "United Kingdom",
    website: "https://www.travelserve.net/",
    domain: "www.travelserve.net",
    openingHours: "Monday - Sunday: 24/7",
    siteParking: "Free restricted parking is available on site.",
    support:
      "Our email support staff is available around the clock to support you with any issues.",
    latitude: "51.49748",
    longitude: "-0.45591",
  },
  settings: {
    currency: "GBP",
    vatRate: 20,
  },
};
