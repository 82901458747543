import React, { useEffect, useState, useCallback } from "react";
import { Grid, Typography, Box, Container, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { errorMessage, formatCurrency } from "utils";
import API from "api";
import { Loading } from "components/Loading";
import { BookingSummary } from "../Summary";
import { config } from "config";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function Stripe() {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleStripe = () => {
    window.location.replace(`${config.api.baseURL}/stripe/checkout/${uuid}`);
  };

  // fetchBookings
  const fetchBooking = useCallback(async () => {
    await API.get(`/meetgreet/v/${uuid}`)
      .then((res) => {
        setData(res.data[0]);
        if (res.data[0].paid === "y") {
          navigation(`/meetgreet/confirmation/${uuid}`);
        }
      })
      .catch((err) => console.log(errorMessage(err)))
      .finally(() => setLoading(false));
  }, [uuid, navigation]);

  // useEffect
  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexGrow: 1,
          py: 4,
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          data && (
            <Container maxWidth="md">
              <Grid container direction="row" spacing={4} sx={{ mb: 8 }}>
                <Grid item md={8} xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom sx={{ my: 3 }}>
                      {`Booking number: ${data.bno} `}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {
                        "Your Booking has been saved in our system. Please review the details about your booking before entering payment information."
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="h5"
                      color="error"
                      sx={{ mb: 2, fontWeight: "bold", my: 2 }}
                    >
                      Total: {formatCurrency(data.total)}
                    </Typography>
                    <Button
                      color="info"
                      variant="contained"
                      endIcon={<ArrowRightAltIcon />}
                      onClick={handleStripe}
                    >
                      Pay with Stripe Checkout
                    </Button>
                  </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                  <BookingSummary mgb={data} />
                </Grid>
              </Grid>
            </Container>
          )
        )}
      </Box>
    </React.Fragment>
  );
}
