import React from "react";
import { Link as RouterLink } from "react-router-dom";

// config
import { config } from "config";

// material ui components
import {
  Container,
  Typography,
  Box,
  Grid,
  Link,
  Button,
  Divider,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// assets
import LogoImg from "assets/logo.png";

const Privacy = () => {
  return (
    <>
      <Container component="main" maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Link component={RouterLink} to="/">
                <img src={LogoImg} alt={config.client.name} />
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Divider variant="middle" sx={{ mt: 4 }} />

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ mt: 2 }}
        >
          <Grid item sm={12} xs={12}>
            <h1>GDPR Compliant Privacy Policy</h1>
            <p>
              <strong>Privacy Policy of www.travelserve.net</strong>
            </p>

            <p>This Website collects some Personal Data from its Users.</p>

            <p>
              Owner and Data Controller
              <br />
              <strong>
                Travel Serve, Novotel London Heathrow Airport, Cherry Lane,
                Heathrow, UB7 9HJ
              </strong>
            </p>

            <p>
              Owner contact email: <strong>info@travelserve.net&nbsp;</strong>
            </p>

            <p>
              <strong>Types of Data collected</strong>
            </p>

            <p>
              Among the types of Personal Data that this Website collects/Apps,
              by itself or through third parties, there are Cookies; Usage Data;
              email addresses; phone numbers.
            </p>

            <p>
              Complete details on each type of Personal Data collected are
              provided in the dedicated sections of this privacy policy or by
              specific explanation texts displayed prior to the Data collection.
            </p>

            <p>
              Personal Data may be freely provided by the User, or, in the case
              of Usage Data, collected automatically when using this Website.
            </p>

            <p>
              Unless specified otherwise, all Data requested by this Website is
              mandatory and failure to provide this Data may make it impossible
              for this Website to provide its services. In cases where this
              Website specifically states that some Data is not mandatory, Users
              are free not to communicate this Data without consequences to the
              availability or the functioning of the Service.
              <br />
              Users who are uncertain about which Personal Data is mandatory are
              welcome to contact the Owner.
            </p>

            <p>
              Any use of Cookies &ndash; or of other tracking tools &ndash; by
              this Website or by the owners of third-party services used by this
              Website serves the purpose of providing the Service required by
              the User, in addition to any other purposes described in the
              present document and in the Cookie Policy, if available.
            </p>

            <p>
              Users are responsible for any third-party Personal Data obtained,
              published or shared through this Website and confirm that they
              have the third party&#39;s consent to provide the Data to the
              Owner.
              <br />
              Mode and place of processing the Data
            </p>

            <p>
              <strong>Methods of processing</strong>
            </p>

            <p>
              The Owner takes appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data.
            </p>

            <p>
              The Data processing is carried out using computers and/or IT
              enabled tools, following organizational procedures and modes
              strictly related to the purposes indicated. In addition to
              the&nbsp;
            </p>

            <p>
              Owner, in some cases, the Data may be accessible to certain types
              of persons in charge, involved with the operation of this Website
              (administration, sales, marketing, legal, system administration)
              or external parties (such as third-party technical service
              providers, mail carriers, hosting providers, IT companies,
              communications agencies) appointed, if necessary, as Data
              Processors by the Owner. The updated list of these parties may be
              requested from the Owner at any time.
              <br />
              <br />
              Legal basis of processing
            </p>

            <p>
              The Owner may process Personal Data relating to Users if one of
              the following applies:
            </p>

            <ul>
              <li>
                Users have given their consent for one or more specific
                purposes. Note: Under some legislations, the Owner may be
                allowed to process Personal Data until the User objects to such
                processing (&ldquo;opt-out&rdquo;), without having to rely on
                consent or any other of the following legal bases. This,
                however, does not apply, whenever the processing of Personal
                Data is subject to European data protection law;
              </li>
              <li>
                provision of Data is necessary for the performance of an
                agreement with the User and/or for any pre-contractual
                obligations thereof;
              </li>
              <li>
                processing is necessary for compliance with a legal obligation
                to which the Owner is subject;
              </li>
              <li>
                processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested
                in the Owner;
              </li>
              <li>
                processing is necessary for the purposes of the legitimate
                interests pursued by the Owner or by a third party.
              </li>
            </ul>

            <p>
              In any case, the Owner will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>

            <p>
              <strong>Place</strong>
            </p>

            <p>
              The Data is processed at the Owner&#39;s operating offices and in
              any other places where the parties involved in the processing are
              located.
              <br />
              &nbsp;
              <br />
              Depending on the User&#39;s location, data transfers may involve
              transferring the User&#39;s Data to a country other than their
              own. To find out more about the place of processing of such
              transferred Data, Users can check the section containing details
              about the processing of Personal Data.
              <br />
              Users are also entitled to learn about the legal basis of Data
              transfers to a country outside the European Union or to any
              international organization governed by public international law or
              set up by two or more countries, such as the UN, and about the
              security measures taken by the Owner to safeguard their Data.
              <br />
              &nbsp;
              <br />
              If any such transfer takes place, Users can find out more by
              checking the relevant sections of this document or inquire with
              the Owner using the information provided in the contact section.
              <br />
              Retention time
              <br />
              Personal Data shall be processed and stored for as long as
              required by the purpose they have been collected for.
              <br />
              Therefore:
            </p>

            <ul>
              <li>
                Personal Data collected for purposes related to the performance
                of a contract between the Owner and the User shall be retained
                until such contract has been fully performed.
              </li>
              <li>
                Personal Data collected for the purposes of the Owner&rsquo;s
                legitimate interests shall be retained as long as needed to
                fulfill such purposes. Users may find specific information
                regarding the legitimate interests pursued by the Owner within
                the relevant sections of this document or by contacting the
                Owner.
              </li>
            </ul>

            <p>
              The Owner may be allowed to retain Personal Data for a longer
              period whenever the User has given consent to such processing, as
              long as such consent is not withdrawn. Furthermore, the Owner may
              be obliged to retain Personal Data for a longer period whenever
              required to do so for the performance of a legal obligation or
              upon order of an authority.
              <br />
              &nbsp;
              <br />
              Once the retention period expires, Personal Data shall be deleted.
              Therefore, the right to access, the right to erasure, the right to
              rectification, and the right to data portability cannot be
              enforced after the expiration of the retention period.
            </p>

            <p>
              <strong>The purposes of processing</strong>
              <br />
              <br />
              The Data concerning the User is collected to allow the Owner to
              provide its Services, as well as for the following purposes:
              Analytics, Interaction with live chat platforms, Interaction with
              external social networks and platforms, User database management,
              and Managing contacts and sending messages.
              <br />
              Users can find further detailed information about such purposes of
              processing and about the specific Personal Data used for each
              purpose in the respective sections of this document.
              <br />
              Detailed information on the processing of Personal Data
              <br />
              Personal Data is collected for the following purposes and using
              the following services:
            </p>

            <ul>
              <li>&nbsp;&nbsp; &nbsp;Analytics</li>
              <li>
                &nbsp;&nbsp; &nbsp;Interaction with external social networks and
                platforms
              </li>
              <li>&nbsp;&nbsp; &nbsp;Interaction with live chat platforms</li>
              <li>&nbsp;&nbsp; &nbsp;Managing contacts and sending messages</li>
              <li>&nbsp;&nbsp; &nbsp;User database management</li>
            </ul>

            <p>
              <br />
              <strong>The rights of Users</strong>
            </p>

            <p>
              Users may exercise certain rights regarding their Data processed
              by the Owner.
              <br />
              In particular, Users have the right to do the following:
            </p>

            <ul>
              <li>
                Withdraw their consent at any time. Users have the right to
                withdraw consent where they have previously given their consent
                to the processing of their Personal Data.
              </li>
              <li>
                Object to processing of their Data. Users have the right to
                object to the processing of their Data if the processing is
                carried out on a legal basis other than consent. Further details
                are provided in the dedicated section below.
              </li>
              <li>
                Access their Data. Users have the right to learn if Data is
                being processed by the Owner, obtain disclosure regarding
                certain aspects of the processing and obtain a copy of the Data
                undergoing processing.
              </li>
              <li>
                Verify and seek rectification. Users have the right to verify
                the accuracy of their Data and ask for it to be updated or
                corrected.
              </li>
              <li>
                Restrict the processing of their Data. Users have the right,
                under certain circumstances, to restrict the processing of their
                Data. In this case, the Owner will not process their Data for
                any purpose other than storing it.
              </li>
              <li>
                Have their Personal Data deleted or otherwise removed. Users
                have the right, under certain circumstances, to obtain the
                erasure of their Data from the Owner.
              </li>
              <li>
                Receive their Data and have it transferred to another
                controller. Users have the right to receive their Data in a
                structured, commonly used, and machine-readable format and, if
                technically feasible, to have it transmitted to another
                controller without any hindrance. This provision is applicable
                provided that the Data is processed by automated means and that
                the processing is based on the User&#39;s consent, on a contract
                of which the User is part, or on pre-contractual obligations
                thereof.
              </li>
              <li>
                Lodge a complaint. Users have the right to bring a claim before
                their competent data protection authority.
              </li>
            </ul>

            <p>
              <strong>Location information</strong>
              <br />
              <br />
              You&rsquo;ll see a request prompted by your device for permission
              to share your location information when you log in, which includes
              location data collected via device GPS and Wi-Fi signals. We use
              your device&#39;s GPS for fetching the most accurate location and
              sometimes if the GPS signal is low or not available we use Wi-Fi
              signals for the location. As a default, for the best service
              available the app asks you to turn on location services
              &ldquo;Allow only while using the app.&rdquo; We use location data
              to assign the nearest bookings for your location and also find
              drivers that are near you and help them navigate to your pickup
              spot. We fetch the device location when the app is in both
              foreground and background states but only when the user is logged
              in.
              <br />
              <br />
              If you&rsquo;re using an android or iOS device, you have 3
              location settings on to choose from:
            </p>

            <ul>
              <li>
                Allow all the time: We may collect location information at any
                time, even when you&rsquo;re not actively using the app. If a
                service needs &ldquo;Allow all the time&rdquo; we&rsquo;ll ask
                for your permission when you enable the service.
              </li>
              <li>
                Allow only while using the app: We may collect location
                information when the app is visible on your screen or when you
                have requested a ride and during your trip. You&rsquo;ll get a
                persistent notification in your Android notification panel if
                location data is being collected in the background when you are
                in the &ldquo;While using the app&rdquo; setting.
              </li>
              <li>
                Deny: This option disables location services for the app. You
                can still use the app, but you&rsquo;ll need to enter your
                pickup and dropoff locations manually. Location information will
                be collected from the driver during your trip and linked to your
                account, even if you have disabled location services for your
                app.
              </li>
            </ul>

            <p>
              <br />
              You can always manage your location settings in your
              device&rsquo;s location preferences.
            </p>

            <p>
              <strong>Details about the right to object to processing</strong>
            </p>

            <p>
              Where Personal Data is processed for a public interest, in the
              exercise of an official authority vested in the Owner or for the
              purposes of the legitimate interests pursued by the Owner, Users
              may object to such processing by providing a ground related to
              their particular situation to justify the objection.
            </p>

            <p>
              Users must know that, however, should their Personal Data be
              processed for direct marketing purposes, they can object to that
              processing at any time without providing any justification. To
              learn, whether the Owner is processing Personal Data for direct
              marketing purposes, Users may refer to the relevant sections of
              this document.
            </p>

            <p>
              <strong>How to exercise these rights</strong>
              <br />
              <br />
              Any requests to exercise User rights can be directed to the Owner
              through the contact details provided in this document. These
              requests can be exercised free of charge and will be addressed by
              the Owner as early as possible and always within one month.
            </p>

            <p>
              <strong>Legal action</strong>
            </p>

            <p>
              The User&#39;s Personal Data may be used for legal purposes by the
              Owner in Court or in the stages leading to possible legal action
              arising from improper use of this Website or the related Services.
              <br />
              The User declares to be aware that the Owner may be required to
              reveal personal data upon request of public authorities.
            </p>

            <p>Additional information about User&#39;s Personal Data</p>

            <p>
              In addition to the information contained in this privacy policy,
              this Website may provide the User with additional and contextual
              information concerning particular Services or the collection and
              processing of Personal Data upon request.
            </p>

            <p>
              <strong>System logs and maintenance</strong>
              <br />
              <br />
              For operation and maintenance purposes, this Website and any
              third-party services may collect files that record interaction
              with this Website (System logs) and use other Personal Data (such
              as the IP Address) for this purpose.
            </p>

            <p>
              <strong>Information not contained in this policy</strong>
              <br />
              <br />
              More details concerning the collection or processing of Personal
              Data may be requested from the Owner at any time. Please see the
              contact information at the beginning of this document.
            </p>

            <p>
              <strong>
                This Website does not support &ldquo;Do Not Track&rdquo;
                requests.
              </strong>
            </p>

            <p>
              To determine whether any of the third-party services it uses honor
              the &ldquo;Do Not Track&rdquo; requests, please read their privacy
              policies.
            </p>

            <p>
              <strong>Changes to this privacy policy</strong>
            </p>

            <p>
              The Owner reserves the right to make changes to this privacy
              policy at any time by giving notice to its Users on this page and
              possibly within this Website and/or - as far as technically and
              legally feasible - sending a notice to Users via any contact
              information available to the Owner. It is strongly recommended to
              check this page often, referring to the date of the last
              modification listed at the bottom.&nbsp;
              <br />
              &nbsp;
              <br />
              Should the changes affect processing activities performed on the
              basis of the User&rsquo;s consent, the Owner shall collect new
              consent from the User, where required.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Typography variant="caption" color="secondary.dark">
              If you require any further information, please call on{" "}
              <Link
                href={`tel:${config.client.telephone.replace(
                  /[^A-Z0-9]+/gi,
                  ""
                )}`}
                color="inherit"
                underline="none"
              >
                {config.client.telephone}
              </Link>{" "}
              or email at{" "}
              <Link
                href={`mailto:${config.client.email}`}
                color="inherit"
                underline="none"
              >
                {config.client.email}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ fontWeight: "bold", mb: 8 }}
              component={RouterLink}
              to="/"
              endIcon={<ArrowRightAltIcon fontSize="small" />}
            >
              Go to Home{" "}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Privacy;
