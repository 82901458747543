import { createContext, useState, useEffect, useReducer } from "react";
import { storage, mgtotal } from "../utils";
import { v4 as uuidv4 } from "uuid";
import { isObject } from "lodash";
import API from "../api";

// local storage field
const MEET_GREET = "mg";

// initialstate
const initialState = {
  isLogged: false,
  values: null,
  mgb: null,
};

// initialmgValues
let defaultmgValues, initialmgValues;
defaultmgValues = initialmgValues = {
  service: "arrival",
  packageName: null,
  packageDetails: {},
  flightDate: new Date(Date.now() + 3600 * 1000 * 24),
  adults: 1,
  children: 0,
  infants: 0,
  total: 0,
  porter: 0,
  buggy: 0,
  trolley: 0,
  cid: 0,
  basket: 0,
  uuid: uuidv4(),
};

// local storage object
const storageObject = storage.get("local", MEET_GREET);

if (storageObject !== undefined) {
  initialmgValues = storageObject;
}

// setMeetGreet
const setMeetGreet = async (currentValues) => {
  storage.set("local", MEET_GREET, currentValues);

  if (currentValues.basket === 1) {
    try {
      await API.post("/meetgreet/basket", {
        values: currentValues,
      });
    } catch (e) {
      console.log(e);
    }
  }
};

// reducer
const mgReducer = (state, action) => {
  switch (action.type) {
    case "MG_BOOKING": {
      const { mgb } = action.payload;

      return {
        ...state,
        mgb: mgb,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const MeetGreetContext = createContext({
  mgValues: initialmgValues,
  setmgValues: () => {},
  mgBooking: () => {},
  mgSaveBooking: () => {},
});

export const MeetGreetProvider = ({ mgValues, children }) => {
  const [state, dispatch] = useReducer(mgReducer, initialState);
  const [currentValues, setCurrentValues] = useState(
    mgValues || initialmgValues
  );

  // mgPaidBooking
  const mgPaidBooking = async (uuid, stripe_id) => {
    await API.put(`/meetgreet/paid/${uuid}`, {
      stripe_id: stripe_id,
    });
  };

  // mgSaveBooking
  const mgSaveBooking = async () => {
    const response = await API.post("/meetgreet/booking", {
      values: currentValues,
    });

    // check jwt for client
    const jwt = storage.get("local", "jwt") || storage.get("session", "jwt");

    // isLogged
    if (isObject(jwt)) {
      initialmgValues.cid = jwt.cid;
    }

    // set session mgb
    storage.set("session", "mgb", response.data);

    // clear localstorage mg
    storage.remove(MEET_GREET);

    defaultmgValues.uuid = uuidv4();
    setCurrentValues(defaultmgValues);

    dispatch({
      type: "MG_BOOKING",
      payload: { mgb: response.data },
    });
  };

  // update values
  const handleUpdateValues = (name, value, packageDetails) => {
    if (packageDetails === undefined || packageDetails === null)
      packageDetails = currentValues.packageDetails;

    let rawValues = {
      ...currentValues,
      [name]: value,
      packageDetails,
    };

    // prices
    if (packageDetails.prices) {
      packageDetails.prices = packageDetails.prices.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
    }

    setCurrentValues({
      ...currentValues,
      [name]: value,
      total: mgtotal(rawValues),
      packageDetails,
    });
  };

  useEffect(() => {
    setMeetGreet(currentValues);
  }, [currentValues]);

  return (
    <MeetGreetContext.Provider
      value={{
        ...state,
        mgValues: currentValues,
        setmgValues: handleUpdateValues,
        setCurrentValues,
        mgPaidBooking,
        mgSaveBooking,
      }}
    >
      {children}
    </MeetGreetContext.Provider>
  );
};

export default MeetGreetContext;
