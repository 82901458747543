import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import { isObject } from "lodash";
import useMeetGreet from "hooks/useMeetGreet";
import Format from "../../Format";
import { toCapitalize, calcVat, calcVatTotal, mgExtrasTotal } from "utils";

import Widget from "pages/Client/Widget";

const OrderSummary = () => {
  const { mgValues, mgb } = useMeetGreet();

  const isBooked = isObject(mgb);

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" align="center" component="div">
          Order Summary
        </Typography>
        <Divider sx={{ mb: 3 }}>
          {!isBooked && mgValues.saved === 0 && (
            <Widget
              variant="outlined"
              label="edit"
              size="small"
              color="primary"
            />
          )}
        </Divider>
        <Stack direction="column" spacing={1}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: "600" }}
          >
            {toCapitalize(mgValues.service)} : Meet & Greet
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {mgValues.packageName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {format(new Date(mgValues.flightDate), "EE, dd MMM yyyy")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {mgValues.adults} Adults, {mgValues.children} Children,{" "}
            {mgValues.infants} Infants
          </Typography>
        </Stack>

        {mgValues.cid === 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" sx={{ mt: 2, display: "flex" }}>
              <Box>
                <Typography variant="span" gutterBottom sx={{ pr: 0.5 }}>
                  Total
                </Typography>
                <Typography variant="caption" gutterBottom>
                  exc. VAT
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="subtitle2" align="right">
                  <Format
                    value={calcVatTotal(mgValues.total)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            </Stack>

            {mgExtrasTotal(mgValues) > 0 && (
              <Stack direction="row" sx={{ mt: 2, display: "flex" }}>
                <Box>
                  <Typography variant="body2">Extra(s)</Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="body2" align="right">
                    <Format
                      value={mgExtrasTotal(mgValues)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </Typography>
                </Box>
              </Stack>
            )}

            <Stack direction="row" sx={{ mt: 2, display: "flex" }}>
              <Box>
                <Typography variant="body2">VAT</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="body2" align="right">
                  <Format
                    value={calcVat(mgValues.total)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ mt: 4, display: "flex" }}>
              <Box>
                <Typography
                  variant="span"
                  gutterBottom
                  sx={{ pr: 0.5, fontWeight: 700, color: "#f50057" }}
                >
                  Total
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ color: "#f50057" }}
                >
                  inc. VAT
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  align="right"
                  sx={{ fontWeight: 700, color: "#f50057" }}
                >
                  <Format
                    value={mgValues.total + mgExtrasTotal(mgValues)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default OrderSummary;
