import React, { useEffect, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import { InputField, SelectField } from "../../FormFields";

// import data
import titles from "../../../data/titles.json";

export default function PassengerForm(props) {
  const { values, setValues, pax, formField } = props;

  // initPassengers
  const initPassengers = useCallback(() => {
    if (values.passengers.length < pax) {
      const passengers = [];
      for (let i = 0; i < pax; i++) {
        passengers.push({
          [formField.title.name]: "",
          [formField.firstName.name]: "",
          [formField.lastName.name]: "",
        });
      }
      setValues({ ...values, passengers });
    }
  }, [formField, pax, setValues, values]);

  useEffect(() => {
    initPassengers();
  }, [initPassengers]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 3 }}>
        Passengers
      </Typography>

      <FieldArray name="passengers">
        {() =>
          values.passengers.map((_, i) => {
            return (
              <Grid key={i} container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Passenger {i + 1}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <SelectField
                    name={`passengers.${i}.title`}
                    label={formField.title.label}
                    data={titles}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <InputField
                    name={`passengers.${i}.firstName`}
                    label={formField.firstName.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <InputField
                    name={`passengers.${i}.lastName`}
                    label={formField.lastName.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
            );
          })
        }
      </FieldArray>
    </React.Fragment>
  );
}
