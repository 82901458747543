import getSymbolFromCurrency from "currency-symbol-map";
import { isEmpty } from "lodash";
import { config } from "../config";
import { isWithinInterval, add, format } from "date-fns";
import countries from "data/countries.json";

// toCapitalize
export const toCapitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

// calcPackagePrice
const calcPackagePrice = (service, prices, pax) => {
  // noPax
  let noPax = Number(pax) < 7 ? Number(pax) : Number(6);

  let arrPrices = prices.split(",");
  let arrPrice = arrPrices.find((price) => {
    const [first] = price.split(":");
    return Number(first) === Number(noPax);
  });
  let price = arrPrice.split(":");

  //groupPrice
  let groupPrice =
    (parseInt(Number(pax)) - parseInt(Number(noPax))) * Number(price[4]);

  let totalPrice = 0;
  switch (service) {
    case "arrival": {
      totalPrice = parseFloat(Number(price[1]) + Number(groupPrice));
      break;
    }
    case "departure": {
      totalPrice = parseFloat(Number(price[2]) + Number(groupPrice));
      break;
    }
    case "connecting": {
      totalPrice = parseFloat(Number(price[3]) + Number(groupPrice));
      break;
    }
    default: {
      totalPrice = 0;
    }
  }
  return totalPrice;
};

const checkPeakDates = (mg, sub) => {
  const dt = new Date(format(new Date(mg.flightDate), "yyyy-M-dd"));
  const from = new Date("2023-12-24");
  const to = add(new Date("2023-12-24"), { days: 8 });

  if (isWithinInterval(dt, { start: from, end: to })) {
    return Number((sub * 50) / 100) + Number(sub);
  } else {
    return sub;
  }
};

// mgtotal
export const mgtotal = (mg) => {
  let sub = 0;
  if (mg.packageDetails.mgpid > 0) {
    if (mg.adults > 0 || mg.children > 0 || mg.infants > 0) {
      sub = calcPackagePrice(
        mg.service,
        mg.packageDetails.prices,
        parseInt(mg.adults + mg.children)
      );

      if (sub === undefined) sub = 0;

      // peak dates
      sub = checkPeakDates(mg, sub);

      //extras
      sub = sub + mgExtrasTotal(mg);

      return parseFloat(sub).toFixed(2);
    } else {
      return sub;
    }
  } else {
    return sub;
  }
};

// mgExtrasTotal
export const mgExtrasTotal = (mg) => {
  return (
    Number(mg.porter) * Number(mg.packageDetails.porter_price) +
    Number(mg.buggy) * Number(mg.packageDetails.buggy_price) +
    Number(mg.trolley) * Number(mg.packageDetails.trolley_price)
  );
};

// mgTotalPassengers
export const mgTotalPassengers = (mg) => {
  if (mg.adults > 0 || mg.children > 0 || mg.infants > 0) {
    return parseInt(mg.adults + mg.children + mg.infants);
  } else {
    return 0;
  }
};

// sleep
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// calcVat
export const calcVat = (num) => {
  if (!isNaN(num)) {
    let vatRate = Number(config.settings.vatRate) / 100;
    return (num * Number(vatRate)) / (1 + Number(vatRate));
  }
};

// calcVatTotal
export const calcVatTotal = (num) => {
  if (!isNaN(num)) {
    return num / (1 + Number(config.settings.vatRate) / 100);
  }
};

// formatCurrency
export const formatCurrency = (amt) => {
  return (
    getSymbolFromCurrency(config.settings.currency) + parseFloat(amt).toFixed(2)
  );
};

// storage
export const storage = {
  remove(key) {
    if (localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  clear(type) {
    if (type === "local" && localStorage) {
      localStorage.clear();
    }

    if (type === "session" && sessionStorage) {
      sessionStorage.clear();
    }
  },

  get(type, key) {
    if (type === "local" && localStorage && localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key)) || null;
    }

    if (type === "session" && sessionStorage && sessionStorage.getItem(key)) {
      return JSON.parse(sessionStorage.getItem(key)) || null;
    }
  },

  set(type, key, value) {
    if (isEmpty(value)) {
      return null;
    }

    if (type === "local" && localStorage) {
      return localStorage.setItem(key, JSON.stringify(value));
    }

    if (type === "session" && sessionStorage) {
      return sessionStorage.setItem(key, JSON.stringify(value));
    }
    return null;
  },
};

// findCountryCode
export const findCountryCode = (name) => {
  let result = countries.filter((country) => {
    return country.name === name;
  });
  if (isEmpty(result)) {
    return "";
  } else {
    return result[0].code;
  }
};

// generateQRCode
export const generateQRCode = (uuid) => {
  const getImageSrc = (uuid) => {
    const SIZE = "300x300";
    const content = config.client.website + "mg/" + uuid;
    const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${content}&choe=UTF-8`;
    return URL;
  };

  let imageData = new Image(300, 300);
  imageData.src = getImageSrc(uuid);
  return imageData;
};

// errorMessage
export const errorMessage = (e) => {
  let error;
  let defaultError = `Sorry, an error occurred while processing your request, please contact our support at ${config.client.telephone}`;
  let errorPastDate = `Sorry, you can't booking online for ${format(
    new Date(e.message),
    "dd/MM/yyyy"
  )}, please cancel and contact support at ${config.client.telephone}`;

  switch (e.code) {
    case "ERR_NETWORK": {
      error = defaultError;
      break;
    }
    case "ERR_BAD_REQUEST": {
      error = e.response.data.message;
      break;
    }
    case "ERR_PAST_DATE": {
      error = errorPastDate;
      break;
    }
    default: {
      error = defaultError;
      break;
    }
  }
  return error;
};

// flightNo
export const flightNo = (data) => {
  let flight;
  switch (data.meet_greet_type) {
    case "Arrival": {
      flight = data.arriving_flight_no;
      break;
    }
    case "Departure": {
      flight = data.departing_flight_no;
      break;
    }
    case "Connecting": {
      flight = data.connecting_flight_no;
      break;
    }
    default: {
      flight = "N/A";
    }
  }
  return flight;
};
