import { createTheme } from "@mui/material/styles";
import { lightGreen, grey } from "@mui/material/colors";

const defaultTheme = createTheme();
const { breakpoints, typography } = defaultTheme;

const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: lightGreen[900],
    },
    secondary: {
      main: grey[500],
    },
    neutral: {
      main: lightGreen[50],
    },
  },
  typography: {
    fontFamily: "'Lexend Deca', sans-serif",
    body1: {
      lineHeight: typography.pxToRem(30),
    },
    h3: {
      fontSize: "3rem",
      [breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(13),
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        loading: {
          fontSize: typography.pxToRem(13),
          color: lightGreen[900],
        },
        option: {
          fontSize: typography.pxToRem(13),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(13),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(13),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          paddingTop: typography.pxToRem(5),
          fontSize: typography.pxToRem(13),
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          padding: "0px",
          fontSize: typography.pxToRem(13),
        },
      },
    },
  },
});

export default theme;
