import React from "react";
import { Box, styled } from "@mui/system";
import { CircularProgress } from "@mui/material";

const Div = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
}));

const Loading = () => {
  return (
    <Div>
      <Box position="relative">
        <CircularProgress />
      </Box>
    </Div>
  );
};

export default Loading;
