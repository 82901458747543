import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PageNotFound from "../assets/404.svg";
import { styled } from "@mui/material/styles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const NotFoundImg = styled("img")(({ theme }) => ({
  width: 450,
}));

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        pt: 5,
      }}
    >
      <NotFoundImg src={PageNotFound} alt="Page Not Found" />
      <Typography variant="h2" sx={{ my: 3, fontWeight: "bold" }}>
        Page Not Found
      </Typography>
      <Button
        sx={{ fontWeight: "bold" }}
        component={RouterLink}
        to="/"
        endIcon={<ArrowRightAltIcon fontSize="small" />}
      >
        Go to Home{" "}
      </Button>
    </Box>
  );
};

export default NotFound;
