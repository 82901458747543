import AuthGuard from "../routes/AuthGuard";
import GreeterAuthGuard from "../routes/GreeterAuthGuard";
import NotFound from "../pages/NotFound";
import HomeRoutes from "../routes/HomeRoutes";
import AuthRoutes from "../routes/AuthRoutes";
import ClientRoutes from "../routes/ClientRoutes";
import GreeterRoutes from "../routes/GreeterRoutes";
import { ClientLayout, HomeLayout, GreeterLayout } from "../components/Layout";
import { Navigate } from "react-router-dom";

export const AllPages = () => {
  const all_routes = [
    {
      element: (
        <AuthGuard>
          <ClientLayout />
        </AuthGuard>
      ),
      children: [...ClientRoutes],
    },
    {
      element: (
        <GreeterAuthGuard>
          <GreeterLayout />
        </GreeterAuthGuard>
      ),
      children: [...GreeterRoutes],
    },
    {
      element: <HomeLayout />,
      children: [...HomeRoutes],
    },
    ...AuthRoutes,
    {
      path: "/",
      element: <Navigate to="/dashboard" />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return all_routes;
};
