import React from "react";
import { Container, Box, Grid, Typography, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// config
import { config } from "../../config";

// material ui styled
import { styled } from "@mui/material/styles";

// theme
import theme from "../../theme";

// assets
import AboutImgSrc from "../../assets/about.jpg";

// Logo
const AboutImg = styled("img")(({ theme }) => ({
  minWidth: "100%",
  height: "auto",
  padding: 0,
  margin: 0,
  borderRadius: "6px",
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
      color: "primary",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// data
const data = [
  {
    id: "1",
    title: "Who We Are?",
    description:
      "Travel Serve Limited was founded in 2007 by Mr Anwar Kureembokus to provide a first class chauffeuring service to the airline, hotel and corporate industries. Since humble beginnings, we now pride ourselves as a company capable of handling the unique requirements of prestigious airline clients and major hotel companies. Our professional team guarantees safe transportation in luxury vehicles which are registered and licensed for such operations. Our company is constantly expanding and we currently cover all major London airports.",
  },
  {
    id: "2",
    title: "Why Choose Us?",
    description:
      "In the last 15 years, Travel Serve has grown from a small team to a much more professional and structured business. We are pleased to have been given the opportunity to work closely with the Accor Hotel Group which led us to develop our working relationship in the Heathrow Airport perimeter. From our original airline contract with Alitalia, we increased our airline portfolio by proving to our clients that we are a reliable company, even in times of great crisis (e.g. Volcanic Ash Cloud, Snow disruptions). As a result of glowing reviews from Airline Station Managers, we have been able to acquire more contracts with other major airlines such as Qatar Airways, Lufthansa, El Al Israel Airlines and many more. Outside of the airport, we are also servicing other corporate affiliates, namely Page & Moy and Oxford Aviation Academy. We are currently responding to tenders for several other reputable corporate companies.",
  },
  {
    id: "3",
    title: "What We Offer?",
    description:
      "Our drivers are highly experienced and well presented. They are trained to provide exemplary customer service and all hold P.C.O licences. Our personnel go the extra mile to give the customers any assistance they may need during their journey. We have a responsive and dedicated office team who are available 24 hours to answer any queries and provide administrative support to our clients.",
  },
];

const About = ({ id }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          About Us
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          A Digital Airport Concierge Company
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          Travel Serve delivers world-class concierage and travel services to
          our global clients.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AboutImg src={AboutImgSrc} alt={config.client.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TabContext value={value}>
            <Box
              sx={{
                borderRadius: "0px",
                px: 2,
                py: 1,
              }}
            >
              <TabList onChange={handleChange}>
                {data.map((service) => (
                  <StyledTab
                    key={service.id}
                    label={service.title}
                    value={service.id}
                  />
                ))}
              </TabList>
            </Box>
            {data.map((service) => (
              <TabPanel key={service.id} value={service.id}>
                <Typography>{service.description}</Typography>
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
