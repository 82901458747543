export const mgFormModel = {
  formId: "meetgreetForm",
  formField: {
    passengers: {
      name: "passengers",
    },
    title: {
      name: "title",
      label: "Title*",
      requiredErrorMsg: "Title is required",
    },
    firstName: {
      name: "firstName",
      label: "First name*",
      requiredErrorMsg: "First name is required",
    },
    nameOnCard: {
      name: "nameOnCard",
      label: "Name on card*",
      requiredErrorMsg: "Name on card is required",
    },
    cardNumber: {
      name: "cardNumber",
      label: "Card number*",
      requiredErrorMsg: "Card number is required",
      invalidErrorMsg: "Card number is not valid (e.g. 4444333322221111)",
    },
    expiryDate: {
      name: "expiryDate",
      label: "Expiry date*",
      requiredErrorMsg: "Expiry date is required",
      invalidErrorMsg: "Expiry date is not valid",
    },
    cvv: {
      name: "cvv",
      label: "CVV*",
      requiredErrorMsg: "CVV is required",
      invalidErrorMsg: "CVV is invalid (e.g. 357)",
    },

    lastName: {
      name: "lastName",
      label: "Last name*",
      requiredErrorMsg: "Last name is required",
    },
    airline: {
      name: "airline",
      label: "Airline*",
      requiredErrorMsg: "Airline is required",
    },
    flightNumber: {
      name: "flightNumber",
      label: "Flight Number*",
      requiredErrorMsg: "Flight Number is required",
      invalidErrorMsg: "Flight number is not valid (e.g. BA98, QR003)",
    },
    flightClass: {
      name: "flightClass",
      label: "Class*",
      requiredErrorMsg: "Flight Class is required",
    },
    flightTime: {
      name: "flightTime",
      label: "Flight Time*",
      requiredErrorMsg: "Flight Time is required",
    },
    noBags: {
      name: "noBags",
      label: "No of Luggage Bags",
      requiredErrorMsg: "No of Luggage Bags is required",
    },
    connectingAirline: {
      name: "connectingAirline",
      label: "Airline*",
      requiredErrorMsg: "Connecting Airline is required",
    },
    connectingFlightNumber: {
      name: "connectingFlightNumber",
      label: "Flight Number*",
      requiredErrorMsg: "Connecting Flight Number is required",
    },
    porter: {
      name: "porter",
      label: "Porter",
    },
    buggy: {
      name: "buggy",
      label: "Buggy",
    },
    wchr: {
      name: "wchr",
      label: "WCHR",
    },
    trolley: {
      name: "trolley",
      label: "Trolley",
    },
    lounge: {
      name: "lounge",
      label: "Lounge",
    },
    address1: {
      name: "address1",
      label: "Address Line 1*",
      requiredErrorMsg: "Address Line 1 is required",
    },
    address2: {
      name: "address2",
      label: "Address Line 2",
    },
    city: {
      name: "city",
      label: "City*",
      requiredErrorMsg: "City is required",
    },
    state: {
      name: "state",
      label: "State/Province/County",
    },
    zipcode: {
      name: "zipcode",
      label: "Postal/Zipcode*",
      requiredErrorMsg: "Postal/Zipcode is required",
      invalidErrorMsg: "Postal/Zipcode is not valid",
    },
    country: {
      name: "country",
      label: "Country*",
      requiredErrorMsg: "Country is required",
    },
    mobile: {
      name: "mobile",
      label: "Mobile number*",
      requiredErrorMsg: "Mobile number is required",
      invalidErrorMsg: "Mobile number is not valid",
    },
    email: {
      name: "email",
      label: "E-Mail address*",
      requiredErrorMsg: "E-Mail address is required",
      invalidErrorMsg: "E-Mail address is not valid",
    },
    terms: {
      name: "terms",
      label: "I have read and agreed to the Terms and Conditions",
      requiredErrorMsg: "Terms and Conditions is required",
      invalidErrorMsg: "Terms and Conditions is not valid",
    },
  },
};

export const mgWidgetFormModel = {
  formId: "meetgreetWidgetForm",
  formField: {
    service: {
      name: "service",
      label: "select your service",
      requiredErrorMsg: "Service is required",
    },
    packageName: {
      name: "packageName",
      label: "Airport",
      requiredErrorMsg: "Package is required",
    },
    flightDate: {
      name: "flightDate",
      label: "Flight Date*",
      requiredErrorMsg: "Flight Date is required",
      invalidErrorMsg: "Flight date is not valid",
    },
  },
};
