import { useContext } from "react";
import { GreeterAuthContext } from "../contexts/GreeterAuthContext";

export const useAuth = () => {
  const context = useContext(GreeterAuthContext);

  if (context === undefined) {
    throw new Error("useAuth() must be used inside a AuthProvider");
  }

  return context;
};
