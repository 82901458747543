import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { storage } from "utils";
import { format } from "date-fns";

import Pdf from "./Pdf";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Booking = (props) => {
  const { handleClose, open } = props;

  let mgb = storage.get("session", "mgb");

  return (
    <div>
      {mgb && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            sx={{ color: "#f50057" }}
          >
            Booking #: {mgb.bno}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Service:
                    </TableCell>
                    <TableCell align="left">
                      {mgb.meet_greet_type}
                      {" : "}
                      {mgb.terminal}
                    </TableCell>
                  </TableRow>
                  {mgb.airline && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Airline:
                      </TableCell>
                      <TableCell align="left">{mgb.airline}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Flight Date & Time:
                    </TableCell>
                    <TableCell align="left">
                      {format(
                        new Date(mgb.booking_date_time),
                        "dd MMM yyyy HH:mm a"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      No of Passengers:
                    </TableCell>
                    <TableCell align="left">{mgb.no_pax}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      No of Bags:
                    </TableCell>
                    <TableCell align="left">{mgb.no_bags}</TableCell>
                  </TableRow>
                  {mgb.lead_name && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Booked By:
                      </TableCell>
                      <TableCell align="left">{mgb.lead_name}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Passenger(s):
                    </TableCell>
                    <TableCell align="left">{mgb.pax_names}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: "#b3e876" }}
                    >
                      Greeter(s):
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ backgroundColor: "#b3e876", fontWeight: "bold" }}
                    >
                      {mgb.greeters ? mgb.greeters : "Not yet assigned"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#f50057", fontWeight: "bold" }}
                    >
                      Status:
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "#f50057", fontWeight: "bold" }}
                    >
                      {mgb.status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          {mgb.mgbsid === 2 && (
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 1,
              }}
            >
              <Pdf />
            </DialogActions>
          )}
        </BootstrapDialog>
      )}
    </div>
  );
};

export default Booking;
