import React from "react";

// views
import { Login } from "../pages/Auth";
import { ForgotPassword } from "../pages/Auth";
import { Signin } from "../pages/Greeter";
import Privacy from "components/Home/Privacy";
import Redirect from "../pages/Redirect";
import { MeetGreet } from "pages/Booking";

const AuthRoutes = [
  { path: "/client", element: <Login /> },
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/greeter", element: <Signin /> },
  { path: "/greeter/signin", element: <Signin /> },
  { path: "/privacy-policy", element: <Privacy /> },
  { path: "/mg/:uuid", element: <MeetGreet /> },
  {
    path: "/admin/*",
    element: <Redirect location={"https://admin.travelserve.net"} />,
  },
];

export default AuthRoutes;
