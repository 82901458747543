import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import API from "api";
import {
  Container,
  Box,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Chip,
  Stack,
  Link,
  Alert,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { flightNo, errorMessage } from "utils";
import { format } from "date-fns";
import { grey, red } from "@mui/material/colors";
import { Loading } from "components/Loading";

// config
import { config } from "config";

// assets
import LogoImg from "assets/logo.png";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const MeetGreet = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // fetchBookings
  const fetchBooking = useCallback(async () => {
    await API.get(`/meetgreet/v/${uuid}`)
      .then((res) => setData(res.data[0]))
      .catch((err) => setError(errorMessage(err)))
      .finally(() => setLoading(false));
  }, [uuid]);

  // useEffect
  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="md">
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <Loading />
        ) : (
          data && (
            <Grid container sx={{ pb: { xs: 2, sm: 3 } }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Link component={RouterLink} to="/">
                    <img src={LogoImg} alt={config.client.name} />
                  </Link>
                </Box>
              </Grid>
              <Grid item sm={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: grey[300],
                          }}
                        >
                          Booking No:
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: grey[300],
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>{data.bno}</Box>
                            <Box>
                              <LocalPrintshopIcon
                                sx={{
                                  color: grey[700],
                                  cursor: "pointer",
                                  "@media print": {
                                    display: "none",
                                  },
                                }}
                                onClick={() => window.print()}
                              />
                            </Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Service:
                        </TableCell>
                        <TableCell align="left">
                          {data.meet_greet_type}
                          {" : "}
                          {data.terminal}
                        </TableCell>
                      </TableRow>
                      {data.airline && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Airline:
                          </TableCell>
                          <TableCell align="left">{data.airline}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Flight No:
                        </TableCell>
                        <TableCell align="left">{flightNo(data)}</TableCell>
                      </TableRow>
                      {data.flight_class && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Flight Class:
                          </TableCell>
                          <TableCell align="left">
                            {data.flight_class}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ backgroundColor: grey[200] }}
                        >
                          Flight Date & Time:
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: grey[200],
                            fontWeight: "bold",
                          }}
                        >
                          {format(
                            new Date(data.booking_date_time),
                            "dd MMM yyyy HH:mm a"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          No of Passengers:
                        </TableCell>
                        <TableCell align="left">
                          Adults{" "}
                          <Chip
                            label={data.adults}
                            color="default"
                            variant="outlined"
                          />{" "}
                          Children{" "}
                          <Chip
                            label={data.children}
                            color="default"
                            variant="outlined"
                          />{" "}
                          Infants{" "}
                          <Chip
                            label={data.infants}
                            color="default"
                            variant="outlined"
                            sx={{ mr: 1 }}
                          />
                          {" = "}{" "}
                          <Chip
                            label={data.no_pax}
                            color="secondary"
                            sx={{ ml: 1, fontWeight: "bold" }}
                          />
                        </TableCell>
                      </TableRow>
                      {Number(data.no_bags) > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            No of Bags:
                          </TableCell>
                          <TableCell align="left">{data.no_bags}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Passenger(s):
                        </TableCell>
                        <TableCell align="left">{data.pax_names}</TableCell>
                      </TableRow>
                      {data.contact_no && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Pax Contact No:
                          </TableCell>
                          <TableCell align="left">{data.contact_no}</TableCell>
                        </TableRow>
                      )}
                      {data.meeting_at && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Meeting at:
                          </TableCell>
                          <TableCell align="left">{data.meeting_at}</TableCell>
                        </TableRow>
                      )}
                      {data.lounge_details && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Lounge Details:
                          </TableCell>
                          <TableCell align="left">
                            {data.lounge_details}
                          </TableCell>
                        </TableRow>
                      )}
                      {Number(data.buggy) > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Buggy:
                          </TableCell>
                          <TableCell align="left">
                            <Chip label={data.buggy} variant="outlined" />{" "}
                            {data.buggy_name} {data.buggy_phone}
                          </TableCell>
                        </TableRow>
                      )}
                      {Number(data.porter) > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Porter:
                          </TableCell>
                          <TableCell align="left">
                            <Chip label={data.porter} variant="outlined" />{" "}
                            {data.porter_name} {data.porter_phone}
                          </TableCell>
                        </TableRow>
                      )}
                      {Number(data.trolley) > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Additional Greeter:
                          </TableCell>
                          <TableCell align="left">
                            <Chip label={data.trolley} variant="outlined" />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {Number(data.chauffeur) > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Chauffeur:
                          </TableCell>
                          <TableCell align="left">
                            <Chip label={data.chauffeur} variant="outlined" />{" "}
                            {data.chauffeur_name} {data.chauffeur_name}
                          </TableCell>
                        </TableRow>
                      )}
                      {data.notes && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Notes:
                          </TableCell>
                          <TableCell align="left">{data.notes}</TableCell>
                        </TableRow>
                      )}
                      {data.greeters && (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ backgroundColor: "#b3e876" }}
                          >
                            Greeter(s):
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: "#b3e876",
                              fontWeight: "bold",
                            }}
                          >
                            {data.greeters ? data.greeters : "Not yet assigned"}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: red[600], fontWeight: "bold" }}
                        >
                          Status:
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: red[600], fontWeight: "bold" }}
                        >
                          {data.status}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )
        )}
      </Container>
    </Box>
  );
};

export default MeetGreet;
