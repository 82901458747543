import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Stack,
  Box,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import { storage, formatCurrency } from "utils";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const BookingSummary = (props) => {
  let mgb;
  if (props.hasOwnProperty("mgb")) {
    mgb = props.mgb;
  } else {
    mgb = storage.get("session", "mgb");
  }

  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardContent>
        <Typography gutterBottom variant="h6" align="center" component="div">
          Booking Summary
        </Typography>
        <Divider sx={{ mb: 3 }}></Divider>
        <Stack direction="column" spacing={1}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: "600" }}
          >
            {mgb.meet_greet_type} : Meet & Greet
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {mgb.terminal}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {format(new Date(mgb.booking_date_time), "EE, dd MMM yyyy")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {mgb.adults} Adults, {mgb.children} Children, {mgb.infants} Infants
          </Typography>
        </Stack>
        {Number(mgb.cid) === 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" sx={{ mt: 4, display: "flex" }}>
              <Box>
                <Typography
                  variant="span"
                  gutterBottom
                  sx={{ pr: 0.5, fontWeight: 700, color: "#f50057" }}
                >
                  Total
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ color: "#f50057" }}
                >
                  (VAT included)
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  align="right"
                  sx={{ fontWeight: 700, color: "#f50057" }}
                >
                  {formatCurrency(mgb.total)}
                </Typography>
              </Box>
            </Stack>
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            size="small"
            endIcon={<LocalPrintshopIcon />}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BookingSummary;
