import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField, CheckboxField } from "../../FormFields";
import useMeetGreet from "hooks/useMeetGreet";

export default function PaymentForm(props) {
  const { mgValues } = useMeetGreet();

  const {
    formField: { firstName, lastName, mobile, email, terms },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 3 }}>
        {mgValues.cid > 0 ? "Account Details" : "Payment"}
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{"Booker's Details"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        {mgValues.cid > 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {"Lead Passenger Contact Details"}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={mobile.name} label={mobile.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CheckboxField name={terms.name} label={terms.label} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
