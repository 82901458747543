import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Link,
  Stack,
  Divider,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CookieConsent from "react-cookie-consent";
import Terms from "./../Home/Terms";

// config
import { config } from "../../config";

const Footer = () => {
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="ts_cookie_consent"
        style={{ background: "#000000", fontWeight: "bold" }}
        buttonStyle={{
          color: "#000",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "5px 15px 5px 15px",
        }}
        expires={150}
      >
        <span style={{ fontSize: "14px" }}>
          This website uses cookies to ensure you get the best experience on our
          website.{" "}
          <Link
            href="/privacy-policy"
            target="_blank"
            color="text.warning"
            underline="hover"
            sx={{ color: "#9a9c9a" }}
          >
            Learn more
          </Link>
        </span>
      </CookieConsent>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexGrow: 1,
          width: "100%",
          backgroundColor: grey[200],
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 8 }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography align="center" variant="body2" color="text.secondary">
                © Copyright {new Date().getFullYear()}, {config.client.name}.
                All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ fontSize: 14 }}
              >
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  color="text.secondary"
                  underline="hover"
                >
                  Privacy Policy
                </Link>
                <Link
                  color="text.secondary"
                  underline="hover"
                  sx={{ cursor: "pointer" }}
                >
                  <Terms />
                </Link>
                <Link
                  href="/passenger-driver-terms-conditions.pdf"
                  target="_blank"
                  color="text.secondary"
                  underline="hover"
                >
                  Transportation T&C
                </Link>
                <Link
                  href="/delete"
                  target="_blank"
                  color="text.secondary"
                  underline="hover"
                >
                  Delete my TSL account
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ pb: 4 }}>
              <Typography variant="body2" color="text.secondary">
                {config.client.name} is a company registered in England and
                Wales with company number {config.client.companyNumber}.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
