import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { pick, keys, isEmpty, has } from "lodash";
import { Formik, Form } from "formik";
import { mgTotalPassengers } from "utils";
import { config } from "config";

// components
import { PassengerForm, FlightForm, BillingForm } from "./Forms";
import { OrderSummary, BookingSummary } from "./Summary";
import { OrderConfirmation } from "./Confirmation";

import validationSchema from "./FormModel/validationSchema";
import { mgFormModel } from "./FormModel/formModel";
import { mgFormInitialValues } from "./FormModel/formInitialValues";

//hooks
import useMeetGreet from "hooks/useMeetGreet";

const steps = ["Passengers", "Flight", "Payment", "Confirmation"];

const { formId, formField } = mgFormModel;

function _renderStepContent(
  step,
  values,
  setValues,
  pax,
  mgValues,
  setmgValues
) {
  switch (step) {
    case 0:
      return (
        !isEmpty(values) && (
          <PassengerForm
            formField={formField}
            values={values}
            setValues={setValues}
            pax={pax}
          />
        )
      );
    case 1:
      return (
        <FlightForm
          formField={formField}
          mgValues={mgValues}
          setmgValues={setmgValues}
        />
      );
    case 2:
      return <BillingForm formField={formField} />;
    case 3:
      return <OrderConfirmation />;
    default:
      return <div>Not Found</div>;
  }
}

export default function MeetGreet() {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const [initialValues, setInitialValues] = useState(mgFormInitialValues);
  const { mgValues, setmgValues, mgSaveBooking } = useMeetGreet();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema =
    activeStep === 2 && mgValues.cid > 0
      ? validationSchema[activeStep + 1]
      : validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 2;

  // final submit action
  const _submitForm = async (values) => {
    mgValues.mg = values;
    try {
      await mgSaveBooking();
      setLoading(false);
      setConfirm(true);

      // redirect
      if (mgValues.cid <= 0) {
        window.location.replace(
          `${config.api.baseURL}/stripe/checkout/${mgValues.uuid}`
        );
      } else {
        setActiveStep(activeStep + 1);
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.response.data.message, { variant: "error" });
    }
  };

  function _handleSubmit(values, actions) {
    setmgValues("mg", values);
    if (isLastStep) {
      setLoading(true);
      _submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      setLoading(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  useEffect(() => {
    if (!has(mgValues, "mg")) {
      setmgValues("mg", mgFormInitialValues);
    }

    setInitialValues(pick(mgValues.mg, keys(mgFormInitialValues)));

    // check total
    if (mgValues.total <= 0 && !confirm) {
      navigation("/");
    }
  }, [mgValues, setmgValues, navigation, confirm]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexGrow: 1,
          py: 9,
        }}
      >
        <Container maxWidth="lg">
          <Grid container direction="row" sx={{ pl: 0 }} spacing={4}>
            <Grid item md={9} xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <Typography
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                            md: "block",
                            lg: "block",
                          },
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <OrderConfirmation />
                ) : (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                  >
                    {({ isSubmitting, values, setValues }) => (
                      <Form id={formId}>
                        {_renderStepContent(
                          activeStep,
                          values,
                          setValues,
                          mgTotalPassengers(mgValues),
                          mgValues,
                          setmgValues
                        )}

                        {!confirm && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 6,
                            }}
                          >
                            <Button
                              color="inherit"
                              variant="contained"
                              disabled={activeStep === 0}
                              onClick={_handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <LoadingButton
                              loading={loading}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              {isLastStep
                                ? `Next: ${
                                    mgValues.cid > 0 ? "Submit" : "Save & Pay"
                                  }`
                                : `Next: ${steps[activeStep + 1]}`}
                            </LoadingButton>
                          </Box>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </React.Fragment>
            </Grid>
            <Grid item md={3} xs={12}>
              {confirm ? <BookingSummary /> : <OrderSummary />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}
