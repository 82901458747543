import { mgFormModel, mgWidgetFormModel } from "./formModel";

const {
  formField: {
    passengers,
    firstName,
    lastName,
    airline,
    flightNumber,
    flightClass,
    noBags,
    flightTime,
    connectingAirline,
    connectingFlightNumber,
    address1,
    address2,
    city,
    state,
    zipcode,
    country,
    mobile,
    email,
    terms,
  },
} = mgFormModel;

const {
  formField: { service, packageName, flightDate },
} = mgWidgetFormModel;

export const mgFormInitialValues = {
  [passengers.name]: [],
  [firstName.name]: "",
  [lastName.name]: "",
  [airline.name]: "",
  [flightNumber.name]: "",
  [flightClass.name]: "",
  [flightTime.name]: "",
  [noBags.name]: "",
  [connectingAirline.name]: "",
  [connectingFlightNumber.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [state.name]: "",
  [zipcode.name]: "",
  [country.name]: "",
  [mobile.name]: "",
  [email.name]: "",
  [terms.name]: false,
};

export const mgWidgetFormInitialValues = {
  [service.name]: "",
  [packageName.name]: "",
  [flightDate.name]: "",
};
