import React, { useState, useEffect, useCallback } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { format, isToday, parseISO } from "date-fns";
import { useAuth } from "hooks/useGreeterAuth";
import { isObject } from "lodash";
import { formatCurrency, storage, flightNo } from "utils";
import API from "api";
import { useSnackbar } from "notistack";

//icons
import CreateIcon from "@mui/icons-material/Create";
import LaunchIcon from "@mui/icons-material/Launch";

// page
import Notes from "./Notes";

export default function Bookings() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { greeter } = useAuth();
  const [open, setOpen] = React.useState(false);

  // isMgb
  const isMgb = isObject(storage.get("session", "mgb"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (params) => {
    if (isToday(parseISO(params.row.booking_date_time))) {
      storage.set("session", "mgb", params.row);
      handleClickOpen();
    } else {
      enqueueSnackbar("Not allowed until the Booking Date", {
        variant: "error",
      });
    }
  };

  const columns = [
    { field: "bno", headerName: "Booking#", width: 100 },
    {
      field: "meet_greet_type",
      headerName: "Service",
      width: 110,
    },
    {
      field: "booking_date_time",
      type: "dateTime",
      headerName: "Flight Date & Time",
      width: 160,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm a");
      },
    },
    { field: "terminal", headerName: "Terminal", width: 180 },
    {
      field: "airline",
      headerName: "Flight#",
      width: 120,
      valueGetter: (params) => {
        return flightNo(params.row);
      },
    },
    { field: "pax_names", headerName: "Passenger(s)", flex: 1 },
    {
      field: "mg_rate",
      type: "number",
      headerName: "Rate",
      width: 70,
      valueFormatter: (params) => {
        return formatCurrency(params.value);
      },
    },
    {
      field: "mg_notes",
      type: "string",
      headerName: "Notes",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography variant="body2" noWrap>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CreateIcon sx={{ fontSize: "20px" }} />}
          label="PDF"
          onClick={() => handleClick(params)}
        />,
        <GridActionsCellItem
          icon={<LaunchIcon sx={{ fontSize: "20px" }} />}
          label="View Booking Details"
          onClick={() => {
            window.open(
              `/mg/${params.row.uuid}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        />,
      ],
    },
  ];

  // fetchBookings
  const fetchBookings = useCallback(async () => {
    await API.get(`/meetgreet/greeter/${greeter.uuid}`)
      .then((res) => setRows(res.data))
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false));
  }, [greeter.uuid]);

  // useEffect
  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  return (
    <>
      {isMgb && <Notes handleClose={handleClose} open={open} />}
      <div style={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            flexGrow: 1,
            height: 400,
            width: "100%",
            "& .today": {
              backgroundColor: "#8bc34a",
              fontWeight: "bold",
            },
            "& .future": {
              backgroundColor: "#ff943975",
            },
          }}
        >
          <DataGrid
            loading={loading}
            rows={rows}
            getRowId={(row) => row.mgbid}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getCellClassName={(params) => {
              return isToday(parseISO(params.row.booking_date_time))
                ? "today"
                : "future";
            }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "booking_date_time",
                    sort: "asc",
                  },
                ],
              },
            }}
          />
        </Box>
      </div>
    </>
  );
}
